// MotorcycleDetails.jsx
import React from 'react';

function MotorcycleDetails({ selectedMotorcycle, selectedMotorcycle2, selectedRate, selectedRate2, startDate, endDate, days, price, price2, startTime, endTime, selectedRate1, selectedMotorcycleImage, selectedMotorcycleImage2, zusatzpaketChecked, priceWithoutPaket, price2WithoutPaket, handleZusatzpaketChange, location, orderStatus, orderData, props, fieldClass, setFieldClass, toggleFieldClass, combiDays, weeklyPeriods, wochenend, wochenend2 }) {
  // Функция для форматирования даты в строковый вид
  // console.log('Order status:', orderStatus); // Вывод статуса заказа в консоль

  console.log('RATE:', selectedRate); // Вывод статуса заказа в консоль

  const smoothScroll = (targetId) => {
    const target = document.getElementById(targetId);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  };




  const handleButtonClick = () => {
    toggleFieldClass();
    // Добавляем тайм-аут, чтобы дать DOM время отрисовать изменения
    setTimeout(() => smoothScroll('personDaten'), 300);
  };


  const formatDate = (date) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('de-DE', options);
  };


  // Функция для форматирования времени в строковый вид без секунд
  const formatTime = (time) => {
    return time.toLocaleTimeString('de-DE', { hour: 'numeric', minute: 'numeric' });
  };

  function getRateDescription(selectedRate1) {
    if (selectedRate1 === 'daily') {
      return 'Tagesmiete';
    } else if (selectedRate1 === 'weekly') {
      return 'Wochenendmiete';
    } else if (selectedRate1 === 'combi') {
      return 'Kombimiete';
    } else {
      return '';
    }
  }


  function getFreeKilometers(selectedRate1, days, combiDays, wochenend, weeklyPeriods) {
    let freeKilometers = 0;
    if (selectedRate1 === 'daily') {
      freeKilometers = 400 * days;
    } else if (selectedRate1 === 'weekly') {
      freeKilometers = 800;
    } else if (selectedRate1 === 'combi') {
      freeKilometers = (400 * combiDays) + (800 * weeklyPeriods);
    }
    return freeKilometers;
  }

  function getFreeKilometers2(selectedRate1, days) {
    let freeKilometers = 0;
    if (selectedRate1 === 'daily') {
      freeKilometers = 400;
    } else if (selectedRate1 === 'weekly') {
      freeKilometers = 800;
    } else if (selectedRate1 === 'combi') {
      freeKilometers = 400;
    }
    return freeKilometers;
  }





  function getDay(selectedRate1) {
    if (selectedRate1 === 'daily') {
      return '1 Tag';
    } else if (selectedRate1 === 'weekly') {
      return '4 Tage';
    } else if (selectedRate1 === 'combi') {
      return '1 Tag';
    } else {
      return '';
    }
  }





  return (
    <div className='motoDetailsContainer'>
      {/* <h2>{getRateDescription(selectedRate1)}</h2> */}
      <h2>{orderData ? 'Deine Buchung' : 'Unser Angebot'}</h2>
      {(selectedMotorcycle) && (
        <div className='motorradContainer'>
          <p>Motorrad:</p>
          <div className='motorrad'>


            {orderData ? (
              // Форма для случая, когда orderData существует
              <div>
                {orderData.statusMotorcycle1 ? (
                  <div>
                    <h3>{selectedMotorcycle}</h3>
                    {/* <img className='motoImageMini' src={`https://admin.motorrad-mieten-kaernten.at/assets/${selectedMotorcycleImage}`} alt="image" /> */}
                    <p className='details_p'>{getRateDescription(selectedRate1)} € {selectedRate},- inkl. {getFreeKilometers2(selectedRate1)} km frei  </p>
                  </div>
                ) : null}
              </div>
            ) : (
              <div>
                <h3>{selectedMotorcycle}</h3>
                {/* <img className='motoImageMini' src={`https://admin.motorrad-mieten-kaernten.at/assets/${selectedMotorcycleImage}`} alt="image" /> */}
                <p className='details_p'>{getRateDescription(selectedRate1)} € {selectedRate},- inkl. {getFreeKilometers2(selectedRate1)} km frei  </p>
              </div>
            )}



            {orderData && orderData.statusMotorcycle2 && (
              <div>
                <h3>{selectedMotorcycle2}</h3>
                {/* <img className='motoImageMini' src={`https://admin.motorrad-mieten-kaernten.at/assets/${selectedMotorcycleImage}`} alt="image" /> */}
                <p className='details_p'>{getRateDescription(selectedRate1)} € {selectedRate2},- inkl. {getFreeKilometers2(selectedRate1)} km frei  </p>
              </div>)}

          </div>
          {/* {getDay(selectedRate1)} {selectedRate} */}



        </div>
      )}



      {(selectedMotorcycle) && (startDate) && (endDate) && (


        <div>
          <div>
            <p>Mietzeitraum:</p>
            <div className='zeitContainer'>
              <div className='details_time'>
                <p className='details_p'>von </p>
                <p><b>{startDate && formatDate(startDate)}</b></p>
                <p>/ ab {startTime && formatTime(startTime)} Uhr</p>
              </div>
              <div className='details_time'>
                <p className='details_p'>bis </p>
                <p><b>{endDate && formatDate(endDate)}</b></p>
                <p>/ {endTime && formatTime(endTime)} Uhr</p>
              </div>
            </div>
          </div>

          {orderData ? (
            <div>
              {orderData.statusMotorcycle1 ? (
                <div className='details_price'>
                  <p>
                    Mietdauer: {days} Tag(e) {selectedRate1 === 'combi' || selectedRate1 === 'weekly' ? '' : `je € ${selectedRate},-`}
                  </p>
                  {selectedRate1 === 'combi' && (
                    <>
                      <p>{combiDays}x Tagesmiete | € {orderData.motorcycle1?.daily_rate},-</p>
                      <p>{weeklyPeriods}x Wochenendmiete | € {orderData.motorcycle1?.weekend},-</p>
                    </>

                  )}
                  <p>
                    Mietpreise: € {priceWithoutPaket},- inkl. {getFreeKilometers(selectedRate1, days, combiDays, wochenend, weeklyPeriods)} km frei
                  </p>

                </div>
              ) : (
                <div className='details_price'>
                  <p>
                    Mietdauer: {days} Tag(e){selectedRate1 === 'combi' || selectedRate1 === 'weekly' ? ':' : `je € ${selectedRate2},-`}
                  </p>
                  {selectedRate1 === 'combi' && (
                    <>
                      <p>{combiDays}x Tagesmiete | € {orderData.motorcycle2?.daily_rate},-</p>
                      <p>{weeklyPeriods}x Wochenendmiete | € {orderData.motorcycle2?.weekend},-</p>
                    </>

                  )}
                  <p>
                    Mietpreise: € {price2WithoutPaket},- inkl. {getFreeKilometers(selectedRate1, days, combiDays, wochenend, weeklyPeriods)} km frei
                  </p>

                </div>

              )
              }
            </div>
          ) : (
            <div className='details_price'>
              <p>
                Mietdauer: {days} Tag(e) {selectedRate1 === 'combi' || selectedRate1 === 'weekly' ? ':' : `je € ${selectedRate},-`}
              </p>
              {selectedRate1 === 'combi' && (
                <>
                  {weeklyPeriods ? <p>{weeklyPeriods} x Wochenendmiete je € {wochenend},-</p> : ''}
                  <p>{combiDays} x Tagesmiete je € {selectedRate},-</p>

                </>
              )}
              <p>
                Mietpreise: € {priceWithoutPaket},- inkl. {getFreeKilometers(selectedRate1, days, combiDays, wochenend, weeklyPeriods)} km frei
              </p>

            </div>
          )}




        </div>


      )}



      {(selectedMotorcycle) && (
        <div className='paketInfo'>
          <p>
            INKLUDIERTES VERSICHERUNGSPAKET
          </p>

          Haftpflicht bis € 15.000.000,-
          <br />
          Kasko Selbstbehalt max. € 2.000,-
          <br />
          Nur in Österreich Kasko versichert!
        </div>
      )}


      {(selectedMotorcycle) && (startDate) && (endDate) && (


        <div>


          {/* <div className='zusatzPaket'>
            <div className='zusatz'>
              <input
                type="checkbox"
                id="zusatzpaket"
                name="zusatzpaket"
                value="zusatzpaket"
                checked={zusatzpaketChecked}
                onChange={handleZusatzpaketChange}
                // disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                disabled={location.pathname.startsWith("/orders") && !!orderData}
              />
              <label className='zusatz_label' htmlFor="zusatzpaket">Option: Zusatzpaket für 69,-</label>
            </div>
            <div className='zusatz_info'>
              <p>
                Inkludiert auch die Reduktion des Kasko Selbstbehaltes auf max. 1.000,00 Euro und gewährt Kasko Versicherungsschutz auch für Kroatien, Italien, Slowenien, Frankreich, Schweiz und Deutschland
              </p>
            </div>
            <p>
              Zusatzpaket: {zusatzpaketChecked ? 'einmalig 69,-' : 'nicht gewählt'}

            </p>
          </div> */}
          Zusatzpaket: {zusatzpaketChecked ? 'einmalig € 69,-' : 'nicht gewählt'}

          {zusatzpaketChecked && (
            <div className='zusatz_info2'>
              <p>
                Inkludiert auch die Reduktion des Kasko Selbstbehaltes auf max. 1.000,00 Euro und gewährt Kasko Versicherungsschutz auch für Kroatien, Italien, Slowenien, Frankreich, Schweiz und Deutschland
              </p>
            </div>
          )}
          <div>

          </div>
          {/* <p className='endPrice'>
            <b>Gesamtpreis {price},-</b>
          </p> */}

          {orderData ? (
            <div>
              {orderData.statusMotorcycle1 ? (
                <p className='endPrice'>
                  <b>Gesamtpreis € {price},-</b>
                </p>
              ) :
                <p className='endPrice'>
                  <b>Gesamtpreis € {price2},-</b>
                </p>}
            </div>) : (
            <p className='endPrice'>
              <b>Gesamtpreis € {price},-</b>
            </p>
          )}

        </div>


      )}





      {/* <div className='btnContainer'>
        {!location.pathname.startsWith("/orders") && endDate && (
          <button
            className='btn'
            onClick={(e) => {
              e.preventDefault(); // Предотвращаем отправку формы по умолчанию
              handleButtonClick(); // Вызываем функцию для изменения класса формы
            }}                            >
            Jetzt Verfügbarkeit Anfragen
          </button>
        )}
      </div> */}




      {!orderData && (
        <div>

          {selectedMotorcycle2 && (
            <div>
              {/* <h2>Alternativ</h2> */}
              <div className='alternativ'>
                <div>
                  <hr />
                  <p>Alternativ:</p>

                  {/* <h2>Motorrad 1</h2> */}
                  <h3>{selectedMotorcycle2}</h3>
                  {/* <img className='motoImageMini' src={`https://admin.motorrad-mieten-kaernten.at/assets/${selectedMotorcycleImage}`} alt="image" /> */}
                  <p className='details_p'>{getRateDescription(selectedRate1)} € {selectedRate2},- inkl. {getFreeKilometers2(selectedRate1)} km frei  </p>
                </div>

              </div>
            </div>
          )}

          {(selectedMotorcycle2) && (startDate) && (endDate) && (

            <div>
              {/* <div>
                 <p>Mietzeitraum:</p>
                <div className='zeitContainer'>
                  <p className='details_p'>von </p>
                <p><b>{startDate && formatDate(startDate)}</b></p>
                <p>/ ab {startTime && formatTime(startTime)} Uhr</p>
               </div>
               <div className='details_time'>
                <p className='details_p'>bis </p>
                <p><b>{endDate && formatDate(endDate)}</b></p>
                  <p>/ bis {endTime && formatTime(endTime)} Uhr</p>
                    </div>
                </div>
                </ div> */}

              <div className='details_price'>
                <p>
                  Mietdauer: {days} Tag(e) {selectedRate1 === 'combi' ? ':' : `je € ${selectedRate2},-`}                </p>
                <p>
                  Mietpreise: € {price2WithoutPaket},- inkl. {getFreeKilometers(selectedRate1, days, combiDays, wochenend, weeklyPeriods)} km frei
                </p>
                {selectedRate1 === 'combi' && (
                  <>
                    {weeklyPeriods ? <p>{weeklyPeriods} x Wochenendmiete € {wochenend2},-</p> : ''}
                    <p>{combiDays} x Tagesmiete je € {selectedRate2},-</p>

                  </>
                )}
                <p>
                  Zusatzpaket: {zusatzpaketChecked ? 'einmalig € 69,-' : 'nicht gewählt'}

                </p>
              </div>

              {/* <p>
                                            <p>
                                              INKLUDIERTES VERSICHERUNGSPAKET
                                            </p>

                                            Haftpflicht bis € 15.000.000,-
                                            <br />
                                          Kasko Selbstbehalt max. € 2.000,-
                                      <br />
                                              Nur in Österreich Kasko versichert!
                                              </p> */}

              {/* 
                                            <div className='zusatzPaket'>
                                      <div className='zusatz'>
                                        <input
                                      type="checkbox"
                                      id="zusatzpaket"
                                      name="zusatzpaket"
                                      value="zusatzpaket"
                                      checked={zusatzpaketChecked}
                                      onChange={handleZusatzpaketChange}
                                      disabled={location.pathname.startsWith("/orders") && props.orderStatus && !!props.orderData}
                                      />
                                      < label className='zusatz_label' htmlFor="zusatzpaket">Option: Zusatzpaket für 69,-</label>
                                      <div className='zusatz_info'>
                                      <p>
                                      Inkludiert auch die Reduktion des Kasko Selbstbehaltes auf max. 1.000,00 Euro und gewährt Kasko Versicherungsschutz auch für Kroatien, Italien, Slowenien, Frankreich, Schweiz und Deutschland
                                    </p>
                                    </div>
                                
                                        </div> */}

              <p>
                <b>Gesamtpreis € {price2},-</b>
              </p>











            </div>



          )}





        </div>

      )}


      {/* {!location.pathname.startsWith("/motorcycle") && ( */}

      {orderData && (
        <>
          <hr />
          <div>
            <h3>
              So gehen wir mit deinen Daten um
            </h3>
            <p>
              Wir erheben oder verarbeiten deine personenbezogene Daten nur, wenn du uns diese Angaben
              freiwillig, z.B. im Rahmen einer Anfrage mitteilst.
              In diesem Fall werden deine Angaben aus der Anfrage inklusive der von dir dort angegebenen
              Kontaktdaten zur Erfüllung des mit der Übermittlung verbundenen Zwecks, z.B. zur Bearbeitung
              deiner Anfrage und für den Fall von Anschlussfragen bei uns gespeichert und genutzt.
              Wenn du uns mit der Erbringung einer Dienstleistung beauftragst, erheben, speichern und nutzen
              wir deine personenbezogenen Daten grundsätzlich nur, soweit es für die Erbringung der
              Dienstleistung oder die Durchführung des Vertrages notwendig ist.
            </p>
          </div>
        </>
      )}
















    </div>




  );
}

export default MotorcycleDetails;
