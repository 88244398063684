import React from 'react';
import img1 from '../../assets/kaernten.png'
import img2 from '../../assets/harrys.png'
import img3 from '../../assets/racers.png'
import './PreFooter.css'
import { FaRegCommentAlt, FaMapMarkerAlt, FaRegClock } from "react-icons/fa";

const PreFooter = () => {
    return (
        <div className="prefooter-container">
            <div className="prefooter-section">
                <h1>UNSERE PARTNER</h1>
                <div className="images-container">
                    <img src={img1} alt="Image 1" />
                    <img src={img2} alt="Image 2" />
                    <img src={img3} alt="Image 3" />
                </div>
            </div>
            <div className="prefooter-section2">
                <div className="images-container2">

                    <div className='preFooter-kontakt'>
                        <div className='kontakt'>
                            <FaRegCommentAlt className="fa-icon" size="50px" color="red" />
                        </div>
                        <div>
                            <h2>Kontakt</h2>
                            <p>
                                Tel: +43 (0)4242 377 33
                            </p>
                            <p>
                                Fax: +43 (0)4242 377 33-50
                            </p>
                            <p>
                                E-Mail: office@megabike.at
                            </p>
                        </div>
                    </div>
                    <div className='preFooter-kontakt'>
                        <div className='mark'>
                            <FaMapMarkerAlt size="50px" color="red" />
                        </div>
                        <div>
                            <h2>Besuchen Sie uns!</h2>
                            <h3>
                                MegaBike Kärnten
                            </h3>
                            <p>
                                Maria-Gailer-Straße 38a, <br /> 9500 Villach
                            </p>
                            <p>
                                <a className='a' href="https://www.google.at/maps/place/Maria-Gailer-Stra%C3%9Fe+38a,+9500+Villach/@46.604417,13.8673653,17z/data=!3m1!4b1!4m6!3m5!1s0x47708028320c9a6d:0x3ce286aaff199cb7!8m2!3d46.604417!4d13.8673653!16s%2Fg%2F11vkgt87b2?entry=ttu" target='blank'> ANFAHRT
                                </a>

                            </p>
                        </div>
                    </div>
                    <div className='preFooter-kontakt'>
                        <div className='time'>
                            <FaRegClock size="50px" color="red" />
                        </div>
                        <div>
                            <h2>Öffnungszeiten</h2>
                            <p>
                                Mo – Fr:  08:00 – 12:00 <br />
                                .13:00 – 18:00
                            </p>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default PreFooter;
