import React, { useState, useEffect } from 'react';
import { getMotorcycles } from '../../services/apiService';
import { Link } from 'react-router-dom';
import './Motorcycles.css'

function Motorcycles({ setSelectedMotorcycle }) { // Добавить setSelectedMotorcycle в список параметров
  const [motorcycles, setMotorcycles] = useState([]);


  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await getMotorcycles();
  //       setMotorcycles(data);
  //     } catch (error) {
  //       console.error('Error fetching motorcycles:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
  const fetchData = async () => {
    try {
      const data = await getMotorcycles();
      // Фильтруем только те мотоциклы, у которых published = true
      const filteredData = data.filter(motorcycle => motorcycle.published === true);
      setMotorcycles(filteredData);
    } catch (error) {
      console.error('Error fetching motorcycles:', error);
    }
  };

  fetchData();
}, []);




  const handleMotorcycleSelect = (name) => {
    setSelectedMotorcycle(name);
    // Сохраняем выбранный мотоцикл в Local Storage
    localStorage.setItem('selectedMotorcycle', name);
  };




  return (
    <div className="motorcycles-container" id="motorcycles" >
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <h1>Für Test-Könige und Kurven-Queens!</h1>

      </div>
      <div className="motorcycles-grid">
        {motorcycles.map(motorcycle => (
          <div key={motorcycle.id} className="motorcycle-card">
            <Link to={`/motorcycle/${motorcycle.Name.replace(/\s+/g, '-').toLowerCase()}`} onClick={() => handleMotorcycleSelect(motorcycle.Name)}>
              <div className="motorcycle-image">
                <img src={`https://admin.motorrad-mieten-kaernten.at/assets/${motorcycle.image}`} alt="image" />
                <div className="motorcycle-details">
                  <h2>{motorcycle.Name}</h2>
                  <p>A € {motorcycle.daily_rate},- B € {motorcycle.weekend},-</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Motorcycles;
