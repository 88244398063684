import React, { useEffect, useRef } from 'react';
import motorcycleImg from '../../assets/motor3.png'; // Обновите путь, если необходимо
import treeImg from '../../assets/tree2.png';
import pineImg from '../../assets/pine.png';
import treesImg from '../../assets/trees.png';
import cloudImg from '../../assets/cloud.png';
import bushImg from '../../assets/bush.png';
import lampImg from '../../assets/lamp.png';
import mountainImg from '../../assets/mountain.png';
import logoImg from '../../assets/logo.png';
import boardImg from '../../assets/board.png';
import lightImg from '../../assets/light.png';
import light2Img from '../../assets/light2.png';
import light5Img from '../../assets/light3.png';

const MotorcycleAnimation = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = 400;
        canvas.height = 150;

        const motorcycleImage = new Image();
        motorcycleImage.src = motorcycleImg;

        const treeImage = new Image();
        treeImage.src = treeImg;

        const pineImage = new Image();
        pineImage.src = pineImg;

        const treesImage = new Image();
        treesImage.src = treesImg;

        const cloudImage = new Image();
        cloudImage.src = cloudImg;

        const bushImage = new Image();
        bushImage.src = bushImg;

        const lampImage = new Image();
        lampImage.src = lampImg;

        const mountainImage = new Image();
        mountainImage.src = mountainImg;

        const logoImage = new Image();
        logoImage.src = logoImg;

        const boardImage = new Image();
        boardImage.src = boardImg;

        const lightImage = new Image();
        lightImage.src = lightImg;

        const light2Image = new Image();
        light2Image.src = light2Img;

        const light5Image = new Image();
        light5Image.src = light5Img;

        const motorcycle = {
            width: 100,
            height: 60,
            x: canvas.width / 2 - 140,
            y: canvas.height - 75,
            draw: function () {
                ctx.drawImage(motorcycleImage, this.x, this.y, this.width, this.height);
            },
            zIndex: 2
        };

        const light = {
            width: 100,
            height: 43,
            x: canvas.width / 2 - 67,
            y: canvas.height - 62,
            draw: function () {
                ctx.drawImage(lightImage, this.x, this.y, this.width, this.height);
            },
            zIndex: 2
        };

        const light2 = {
            width: 55,
            height: 25,
            x: canvas.width / 2 - 13,
            y: canvas.height - 30,
            draw: function () {
                ctx.drawImage(light2Image, this.x, this.y, this.width, this.height);
            },
            zIndex: 2
        };

        const light3 = {
            width: 55,
            height: 25,
            x: canvas.width / 2 - 13,
            y: canvas.height - 30,
            draw: function () {
                ctx.drawImage(light2Image, this.x, this.y, this.width, this.height);
            },
            zIndex: 2
        };

        const light4 = {
            width: 25,
            height: 25,
            x: canvas.width / 2 - 81,
            y: canvas.height - 72,
            draw: function () {
                ctx.drawImage(light2Image, this.x, this.y, this.width, this.height);
            },
            zIndex: 2
        };

        const light5 = {
            width: 110,
            height: 110,
            x: canvas.width / 2 - 181,
            y: canvas.height - 106,
            draw: function () {
                ctx.drawImage(light5Image, this.x, this.y, this.width, this.height);
            },
            zIndex: 2
        };

        const objects = [
            { x: canvas.width + 0, y: canvas.height - 120, width: 700, height: 70, image: mountainImage, speed: 1, zIndex: 1 },
            { x: canvas.width + 10, y: canvas.height - 112, width: 70, height: 70, image: boardImage, speed: 2, zIndex: 1 },
            { x: canvas.width + 10, y: canvas.height - 100, width: 70, height: 30, image: logoImage, speed: 2, zIndex: 1 },
            { x: canvas.width + 550, y: canvas.height - 150, width: 50, height: 50, image: cloudImage, speed: 5, zIndex: 1 },
            // { x: canvas.width + 0, y: canvas.height - 125, width: 100, height: 100, image: pineImage, speed: 4, zIndex: 1 },
            { x: canvas.width + 50, y: canvas.height - 165, width: 150, height: 150, image: treeImage, speed: 4, zIndex: 1 },
            { x: canvas.width + 250, y: canvas.height - 100, width: 180, height: 180, image: treeImage, speed: 4, zIndex: 5 },
            { x: canvas.width + 400, y: canvas.height - 100, width: 130, height: 130, image: pineImage, speed: 4, zIndex: 4 },
            { x: canvas.width + 850, y: canvas.height - 183, width: 150, height: 150, image: treesImage, speed: 4, zIndex: 1 },
            { x: canvas.width + 850, y: canvas.height - 138, width: 110, height: 110, image: lampImage, speed: 4, zIndex: 1 },
            { x: canvas.width + 1100, y: canvas.height - 138, width: 110, height: 110, image: lampImage, speed: 4, zIndex: 1 },
            { x: canvas.width + 850, y: canvas.height - 200, width: 150, height: 150, image: cloudImage, speed: 5, zIndex: 3 },
            { x: canvas.width + 150, y: canvas.height - 150, width: 80, height: 80, image: cloudImage, speed: 5, zIndex: 2 },
            { x: canvas.width + 425, y: canvas.height - 60, width: 70, height: 70, image: bushImage, speed: 4, zIndex: 3 },
        ];

        const markings = [
            { x: 0, y: canvas.height - 10, width: 10, height: 2, color: '#555', speed: 4, zIndex: 0 },
            { x: 55, y: canvas.height - 10, width: 10, height: 2, color: '#555', speed: 4, zIndex: 0 },
            { x: 105, y: canvas.height - 10, width: 10, height: 2, color: '#555', speed: 4, zIndex: 0 },
            { x: 155, y: canvas.height - 10, width: 10, height: 2, color: '#555', speed: 4, zIndex: 0 },
            { x: 205, y: canvas.height - 10, width: 10, height: 2, color: '#555', speed: 4, zIndex: 0 },
            { x: 255, y: canvas.height - 10, width: 10, height: 2, color: '#555', speed: 4, zIndex: 0 },
            { x: 305, y: canvas.height - 10, width: 10, height: 2, color: '#555', speed: 4, zIndex: 0 },
            { x: 355, y: canvas.height - 10, width: 10, height: 2, color: '#555', speed: 4, zIndex: 0 },
        ];

        function drawRoad() {
            ctx.fillStyle = '#999';
            ctx.fillRect(0, canvas.height - 30, canvas.width, 25);

            ctx.fillStyle = '#555';
            ctx.fillRect(0, canvas.height - 10, canvas.width, 4);

            ctx.fillStyle = '#777';
            ctx.fillRect(0, canvas.height - 30, canvas.width, 2);
        }

        function drawMarkings() {
            markings.forEach(mark => {
                ctx.fillStyle = mark.color;
                ctx.fillRect(mark.x, canvas.height - 20, mark.width, mark.height);
            });
        }

        function updateObjects() {
            objects.forEach(obj => {
                obj.x -= obj.speed;
                if (obj.x + obj.width < 0) {
                    obj.x = canvas.width;
                }
            });
        }

        function updateMarkings() {
            markings.forEach(mark => {
                mark.x -= mark.speed;
                if (mark.x + mark.width < 0) {
                    mark.x = canvas.width;
                }
            });
        }

        let squareAngle = 0;
        const groupCenterX1 = canvas.width / 2 - 60; // Положение центра первой группы
        const groupCenterY1 = canvas.height / 2 + 42;
        
        const groupCenterX2 = canvas.width / 2 + -127; // Положение центра второй группы
        const groupCenterY2 = canvas.height / 2 + 42;
        
        const squares = [
            { radius: 5.5, angleOffset: 0, x: groupCenterX1, y: groupCenterY1, color: '#403269', size: 3.5, speed: 0.1, zIndex: 1 },
            { radius: 5.5, angleOffset: 80, x: groupCenterX1, y: groupCenterY1, color: '#403269', size: 3.5, speed: 0.1, zIndex: 1 },
            { radius: 5.5, angleOffset: 160, x: groupCenterX1, y: groupCenterY1, color: '#403269', size: 3.5, speed: 0.1, zIndex: 1 },
            { radius: 5.5, angleOffset: 240, x: groupCenterX1, y: groupCenterY1, color: '#403269', size: 3.5, speed: 0.1, zIndex: 1 }
        ];
        
        const squares2 = [
            { radius: 5.5, angleOffset: 0, x: groupCenterX2, y: groupCenterY2, color: '#403269', size: 3.5, speed: 0.1, zIndex: 1 },
            { radius: 5.5, angleOffset: 80, x: groupCenterX2, y: groupCenterY2, color: '#403269', size: 3.5, speed: 0.1, zIndex: 1 },
            { radius: 5.5, angleOffset: 160, x: groupCenterX2, y: groupCenterY2, color: '#403269', size: 3.5, speed: 0.1, zIndex: 1 },
            { radius: 5.5, angleOffset: 240, x: groupCenterX2, y: groupCenterY2, color: '#403269', size: 3.5, speed: 0.1, zIndex: 1 }
        ];
        
        function updateSquares() {
            squareAngle += 19; // Увеличьте это значение для ускорения анимации
        
            squares.forEach(square => {
                const angle = (squareAngle + square.angleOffset) * Math.PI / 180;
                square.x = groupCenterX1 + square.radius * Math.cos(angle);
                square.y = groupCenterY1 + square.radius * Math.sin(angle);
            });
        
            squares2.forEach(square => {
                const angle = (squareAngle + square.angleOffset) * Math.PI / 180;
                square.x = groupCenterX2 + square.radius * Math.cos(angle);
                square.y = groupCenterY2 + square.radius * Math.sin(angle);
            });
        }
        
        function drawSquares() {
            squares.forEach(square => {
                ctx.fillStyle = square.color;
                ctx.fillRect(square.x, square.y, square.size, square.size);
            });
        }
        
        function drawSquares2() {
            squares2.forEach(square => {
                ctx.fillStyle = square.color;
                ctx.fillRect(square.x, square.y, square.size, square.size);
            });
        }
        
        function animate() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        
            drawRoad();
            drawMarkings();
            updateMarkings();
            updateSquares();
            updateObjects();
        
            // Нарисовать мотоцикл
            motorcycle.draw();
        
            // Нарисовать квадраты первой группы
            drawSquares();
        
            // Нарисовать квадраты второй группы
            drawSquares2();
        
            // Нарисовать остальные объекты
            const allObjects = [...objects, ...markings, motorcycle, light, light2, light3, light4, light5];
            allObjects.sort((a, b) => a.zIndex - b.zIndex);
        
            allObjects.forEach(obj => {
                if (obj.draw) {
                    obj.draw();
                } else if (obj.image) {
                    ctx.drawImage(obj.image, obj.x, obj.y, obj.width, obj.height);
                } else {
                    ctx.fillStyle = obj.color;
                    ctx.fillRect(obj.x, obj.y, obj.width, obj.height);
                }
            });
        
            requestAnimationFrame(animate);
        }
        
        animate();
        
        

    }, []);

    return (
        <div className='anim' style={{ width: '100%', height: 'auto', position: 'relative', background: '' }}>
            <canvas
                ref={canvasRef}
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'block',
                    backgroundImage: 'url(/path/to/back3.png)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
            />
        </div>
    );
};

export default MotorcycleAnimation;
