// // CustomDatePicker.jsx
// import React, { useEffect, useState } from 'react';
// import Select from 'react-select'

// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './Form.css';


// function CustomDatePicker({ startDate, endDate, setStartDate, setEndDate, selectedRate, selectedRate2, selectedMotorcycle, selectedMotorcycle2, motorcycles, setDays, setPrice, setPrice2, startTime, endTime, setStartTime, setEndTime, formattedStartDate, formattedEndDate, zusatzpaketChecked, setPriceWithoutPaket, setPrice2WithoutPaket }) {
//   // Функция для проверки, является ли день рабочим
//   // const [startTime, setStartTime] = useState(null);
//   // const [endTime, setEndTime] = useState(null);
//   // console.log(zusatzpaketChecked);

//   const today = new Date();


//   const isWorkingDay = (date) => {
//     // Если выбран weekly, закрыть дни с вторника по пятницу
//     // if (selectedRate === 'weekly') {
//     //   const day = date.getDay(); // 0 для воскресенья, 1 для понедельника, и т. д.
//     //   return day !== 2 && day !== 3 && day !== 4; // Вернуть true для понедельника, среды, четверга и пятницы
//     // }
//     if (selectedRate === 'weekly') {
//       const day = date.getDay(); // 0 для воскресенья, 1 для понедельника, и т. д.
//       return day === 5; // Вернуть true только для пятницы
//     }
//     // Если выбран daily, закрыть субботу и воскресенье
//     if (selectedRate === 'daily' || selectedRate === 'combi') {
//       const day = date.getDay(); // 0 для воскресенья, 1 для понедельника, и т. д.
//       return day !== 0 && day !== 6; // Вернуть true для всех дней кроме субботы и воскресенья
//     }
//     return true; // По умолчанию считаем, что день открыт
//   };

//   // Функция для вычисления цены
//   // const calculatePrice = (zusatzpaketChecked) => {
//   //   if (!startDate || !endDate) return 0; // Если начальная или конечная даты не выбраны, вернуть 0
//   //   const oneDay = 24 * 60 * 60 * 1000; // Количество миллисекунд в одном дне
//   //   const days = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1 || 1; // Вычисляем количество дней между начальной и конечной датами
//   //   const selectedMotorcycleObject = motorcycles.find(motorcycle => motorcycle.Name === selectedMotorcycle);
//   //   if (!selectedMotorcycleObject) return 0; // Если выбранный мотоцикл не найден, вернуть 0

//   //   let totalPrice = 0;

//   //   if (selectedRate === 'weekly') {
//   //     // Если выбрана опция weekly, то цена за всю неделю
//   //     totalPrice = selectedMotorcycleObject.weekend;
//   //   } else if (selectedRate === 'combi') {
//   //     // Если выбрана опция combi, то цена за каждый день по тарифу combi
//   //     totalPrice = days * selectedMotorcycleObject.combi;
//   //   } else {
//   //     // Иначе учитываем количество выбранных дней
//   //     let rate = selectedRate === 'daily' ? selectedMotorcycleObject.daily_rate : selectedMotorcycleObject.weekend;
//   //     totalPrice = days * rate;
//   //   }

//   //   if (zusatzpaketChecked) {
//   //     totalPrice += 69; // Добавляем 69 к общей сумме, если zusatzpaketChecked установлен
//   //   }

//   //   return totalPrice; // Возвращаем общую стоимость
//   // };


//   // const countDaysInCombiPeriods = (startDate, endDate) => {
//   //   const oneDay = 24 * 60 * 60 * 1000;
//   //   const periods = { combiDays: 0, weeklyPeriods: 0 };

//   //   let currentDate = new Date(startDate);

//   //   while (currentDate <= endDate) {
//   //     const day = currentDate.getDay();
//   //     const nextDate = new Date(currentDate.getTime() + oneDay);

//   //     if (day >= 2 && day <= 4) {
//   //       // Вт - Чт считаем по combi тарифу
//   //       periods.combiDays++;
//   //     } else if (day === 5) {
//   //       // Пт начинаем новый период weekly
//   //       periods.weeklyPeriods++;
//   //       // Перейдем к понедельнику следующей недели, чтобы исключить дублирование
//   //       currentDate.setDate(currentDate.getDate() + (8 - day));
//   //       continue;
//   //     } else if (day === 6 || day === 0) {
//   //       // Сб - Вс считаем как часть текущего weekly периода
//   //       periods.weeklyPeriods++;
//   //     }

//   //     currentDate = nextDate;
//   //   }

//   //   return periods;
//   // };

//   // const calculatePrice = (zusatzpaketChecked) => {
//   //   if (!startDate || !endDate) return 0;

//   //   const { combiDays, weeklyPeriods } = countDaysInCombiPeriods(startDate, endDate);
//   //   const selectedMotorcycleObject = motorcycles.find(motorcycle => motorcycle.Name === selectedMotorcycle);
//   //   if (!selectedMotorcycleObject) return 0;

//   //   let totalPrice = 0;

//   //   if (selectedRate === 'daily') {
//   //     const totalDays = Math.round(Math.abs((endDate - startDate) / (24 * 60 * 60 * 1000))) + 1;
//   //     totalPrice = totalDays * selectedMotorcycleObject.daily_rate;
//   //   } else if (selectedRate === 'weekly') {
//   //     totalPrice = selectedMotorcycleObject.weekend;
//   //   } else if (selectedRate === 'combi') {
//   //     totalPrice = combiDays * selectedMotorcycleObject.combi + weeklyPeriods * selectedMotorcycleObject.weekend;
//   //   }

//   //   if (zusatzpaketChecked) {
//   //     totalPrice += 69;
//   //   }

//   //   return totalPrice;
//   // };



//   // const countDaysInCombiPeriods = (startDate, endDate, startTime, endTime) => {
//   //   const oneDay = 24 * 60 * 60 * 1000;
//   //   let periods = { combiDays: 0, weeklyPeriods: 0 };

//   //     // Если стартовая и конечная даты совпадают, считаем за 1 combiDay
//   // if (startDate.toDateString() === endDate.toDateString()) {
//   //   periods.combiDays++;
//   //   return periods;
//   // }

//   //   let currentDate = new Date(startDate);

//   //   while (currentDate <= endDate) {
//   //     const day = currentDate.getDay();



//   //     // Обработка пятницы
//   //     if (day === 5) {
//   //       // Проверяем время для пятницы
//   //       if (currentDate.toDateString() === startDate.toDateString()) {
//   //         // Если стартовая дата — пятница
//   //         if (startTime && startTime.getHours() === 8 && startTime.getMinutes() === 30) {
//   //           periods.combiDays++;
//   //         }
//   //       }
//   //       currentDate.setDate(currentDate.getDate() + 2); // Переход на воскресенье
//   //       continue;
//   //     }

//   //     // Обработка понедельника
//   //     if (day === 1) {
//   //       // Проверяем время для понедельника
//   //       if (currentDate.toDateString() === endDate.toDateString()) {
//   //         // Если конечная дата — понедельник
//   //         if (endTime && endTime.getHours() === 17 && endTime.getMinutes() === 30) {
//   //           periods.combiDays++;
//   //         }
//   //       }
//   //       currentDate.setDate(currentDate.getDate() + 1); // Переход на вторник
//   //       continue;
//   //     }

//   //     // Обработка вторника, среды и четверга
//   //     if (day >= 1 && day <= 4) {
//   //       periods.combiDays++;
//   //     }

//   //     // Обработка субботы и воскресенья
//   //     if (day === 6 || day === 0) {
//   //       periods.weeklyPeriods++;
//   //     }

//   //     currentDate.setDate(currentDate.getDate() + 1); // Переход на следующий день
//   //   }

//   //   return periods;
//   // };




// // ++++++++++++++++++++++++++++++



//   // const countDaysInCombiPeriods = (startDate, endDate, startTime, endTime) => {
//   //   const oneDay = 24 * 60 * 60 * 1000;
//   //   let periods = { combiDays: 0, weeklyPeriods: 0 };

//   //   // Если стартовая и конечная даты совпадают, считаем за 1 combiDay
//   //   if (startDate.toDateString() === endDate.toDateString()) {
//   //     periods.combiDays++;
//   //     return periods;
//   //   }

//   //   let currentDate = new Date(startDate);

//   //   while (currentDate <= endDate) {
//   //     const day = currentDate.getDay();

//   //     // Обработка вторника, среды и четверга
//   //     if (day >= 2 && day <= 4) {
//   //       periods.combiDays++;
//   //     }

//   //     // Обработка пятницы
//   //     if (day === 5) {
//   //       if (currentDate.toDateString() === startDate.toDateString()) {
//   //         if (startTime && startTime.getHours() === 8 && startTime.getMinutes() === 30) {
//   //           periods.combiDays++;
//   //         }
//   //       } else {
//   //         periods.combiDays++;
//   //       }
//   //     }

//   //     // Обработка субботы и воскресенья
//   //     if (day === 6 || day === 0) {
//   //       periods.weeklyPeriods++;
//   //     }

//   //     // Обработка понедельника
//   //     if (day === 1) {
//   //       if (currentDate.toDateString() === endDate.toDateString()) {
//   //         if (endTime && endTime.getHours() === 17 && endTime.getMinutes() === 30) {
//   //           periods.combiDays++;
//   //         }
//   //       } else {
//   //         periods.combiDays++;
//   //       }
//   //     }

//   //     currentDate.setDate(currentDate.getDate() + 1);
//   //   }

//   //   if (periods.weeklyPeriods > 0) {
//   //     periods.weeklyPeriods = 1;
//   //   }

//   //   return periods;
//   // };



//   // const countDaysInCombiPeriods = (startDate, endDate, startTime, endTime) => {
//   //   const oneDay = 24 * 60 * 60 * 1000;
//   //   let periods = { combiDays: 0, weeklyPeriods: 0 };
//   //   let currentDate = new Date(startDate);
//   //   let currentWeekNumber = getWeekNumber(currentDate);

//   //   // Если стартовая и конечная даты совпадают, считаем за 1 combiDay
//   //   // if (startDate.toDateString() === endDate.toDateString()) {
//   //   //   periods.combiDays++;
//   //   //   return periods;
//   //   // }

//   //   if (startDate.toDateString() === endDate.toDateString()) {
//   //     const day = startDate.getDay();
//   //     if (day >= 2 && day <= 4) { // Вт - Чт
//   //         periods.combiDays++;
//   //     } else if (day === 5) { // Пт
//   //         if (startTime && startTime.getHours() === 8 && startTime.getMinutes() === 30) {
//   //             periods.combiDays++;
//   //         }
//   //     } else if (day === 1) { // Пн
//   //         if (endTime && endTime.getHours() === 17 && endTime.getMinutes() === 30) {
//   //             periods.combiDays++;
//   //         }
//   //     }
//   //     return periods;
//   // }

//   //   const weeksCounted = new Set(); // Set для отслеживания уникальных недель, в которых уже считались выходные

//   //   while (currentDate <= endDate) {
//   //     const day = currentDate.getDay();
//   //     const weekNumber = getWeekNumber(currentDate);

//   //     // Обработка вторника, среды и четверга
//   //     if (day >= 2 && day <= 4) {
//   //       periods.combiDays++;
//   //     }

//   //     // Обработка пятницы
//   //     if (day === 5) {
//   //       if (currentDate.toDateString() === startDate.toDateString()) {
//   //         if (startTime && startTime.getHours() === 8 && startTime.getMinutes() === 30) {
//   //           periods.combiDays++;
//   //         }
//   //       } else {
//   //         periods.combiDays++;
//   //       }
//   //     }

//   //     // Обработка субботы и воскресенья, считаем только один раз за каждую неделю
//   //     if ((day === 6 || day === 0) && !weeksCounted.has(weekNumber)) {
//   //       periods.weeklyPeriods++;
//   //       weeksCounted.add(weekNumber);
//   //     }

//   //     // Обработка понедельника
//   //     if (day === 1) {
//   //       if (currentDate.toDateString() === endDate.toDateString()) {
//   //         if (endTime && endTime.getHours() === 17 && endTime.getMinutes() === 30) {
//   //           periods.combiDays++;
//   //         }
//   //       } else {
//   //         periods.combiDays++;
//   //       }
//   //     }


//   //     currentDate.setDate(currentDate.getDate() + 1);
//   //   }

//   //   return periods;
//   // };

//   // const countDaysInCombiPeriods = (startDate, endDate, startTime, endTime) => {
//   //   if (!startDate || !endDate) {
//   //     return null;
//   //   }

//   //   const oneDay = 24 * 60 * 60 * 1000;
//   //   let periods = { combiDays: 0, weeklyPeriods: 0 };

//   //   let currentStartDate = new Date(startDate); // создаем копию startDate
//   //   let currentEndDate = new Date(endDate); // создаем копию endDate

//   //   currentStartDate.setHours(0, 0, 0, 0);
//   //   currentEndDate.setHours(23, 59, 59, 999); // Включаем весь день завершения

//   //   if (currentStartDate.toDateString() === currentEndDate.toDateString()) {
//   //     const day = currentStartDate.getDay();
//   //     if (day >= 2 && day <= 4) { // Вт - Чт
//   //       periods.combiDays++;
//   //     } else if (day === 5) { // Пт
//   //       if (startTime && startTime.getHours() === 8 && startTime.getMinutes() === 30) {
//   //         periods.combiDays++;
//   //       }
//   //     } else if (day === 1) { // Пн
//   //       if (endTime && endTime.getHours() === 17 && endTime.getMinutes() === 30) {
//   //         periods.combiDays++;
//   //       }
//   //     }
//   //     console.log(`Date: ${currentStartDate}, Day: ${day}, CombiDays: ${periods.combiDays}, WeeklyPeriods: ${periods.weeklyPeriods}`);

//   //     return periods;
//   //   }

//   //   const weeksCounted = new Set(); // Set для отслеживания уникальных недель, в которых уже считались выходные

//   //   while (currentStartDate <= currentEndDate) {
//   //     const day = currentStartDate.getDay();
//   //     const weekNumber = getWeekNumber(currentStartDate);

//   //     // Обработка вторника, среды и четверга
//   //     if (day >= 2 && day <= 4) {
//   //       periods.combiDays++;
//   //     }

//   //     // Обработка пятницы
//   //     if (day === 5) {
//   //       if (currentStartDate.toDateString() === startDate.toDateString()) {
//   //         if (startTime && startTime.getHours() === 8 && startTime.getMinutes() === 30) {
//   //           periods.combiDays++;
//   //         }
//   //       } else {
//   //         periods.combiDays++;
//   //       }
//   //     }


//   //   // Обработка субботы
//   // if (day === 6 && !weeksCounted.has(weekNumber)) {
//   //   periods.weeklyPeriods++;
//   //   weeksCounted.add(weekNumber);
//   // }

//   // // Обработка воскресенья (считаем только если суббота не была обработана)
//   // // if (day === 0 && !weeksCounted.has(weekNumber)) {
//   // //   periods.weeklyPeriods++;
//   // //   weeksCounted.add(weekNumber);
//   // // }

//   //     // Обработка понедельника
//   //     if (day === 1) {
//   //       if (currentStartDate.toDateString() === endDate.toDateString()) {
//   //         if (endTime && endTime.getHours() === 17 && endTime.getMinutes() === 30) {
//   //           periods.combiDays++;
//   //         }
//   //       } else {
//   //         periods.combiDays++;
//   //       }
//   //     }



//   //     // Debug output for each iteration
//   //     console.log(`Date: ${currentStartDate}, Day: ${day}, CombiDays: ${periods.combiDays}, WeeklyPeriods: ${periods.weeklyPeriods}`);

//   //     currentStartDate.setDate(currentStartDate.getDate() + 1);
//   //   }

//   //   return periods;
//   // };


//   // // Вспомогательная функция для получения номера недели
//   // const getWeekNumber = (date) => {
//   //   const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
//   //   const pastDaysOfYear = (date - firstDayOfYear) / 86400000;

//   //   return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
//   // };



//   const countDaysInCombiPeriods = (startDate, endDate, startTime, endTime) => {
//     const oneDay = 24 * 60 * 60 * 1000;

//     // Приводим даты к началу дня
//     const start = new Date(startDate);
//     const end = new Date(endDate);

//     // Устанавливаем время на начало дня
//     start.setHours(0, 0, 0, 0);
//     end.setHours(0, 0, 0, 0);

//     // Считаем количество дней
//     const totalDays = Math.round((end - start) / oneDay) + 1;

//     // Если startDate и endDate совпадают, возвращаем 1 день
//     if (totalDays <= 0) return { combiDays: 1, weeklyPeriods: 0 };

//     return { combiDays: totalDays, weeklyPeriods: 0 }; // пока weeklyPeriods не учитываем
// };



//   const calculatePrice = (zusatzpaketChecked) => {
//     if (!startDate || !endDate) return 0;

//     const { combiDays, weeklyPeriods } = countDaysInCombiPeriods(startDate, endDate, startTime, endTime);
//     const selectedMotorcycleObject = motorcycles.find(motorcycle => motorcycle.Name === selectedMotorcycle);
//     if (!selectedMotorcycleObject) return 0;

//     let totalPrice = 0;

//     if (selectedRate === 'daily') {
//       const totalDays = Math.round(Math.abs((endDate - startDate) / (24 * 60 * 60 * 1000))) + 1;
//       totalPrice = totalDays * selectedMotorcycleObject.daily_rate;
//     } else if (selectedRate === 'weekly') {
//       totalPrice = selectedMotorcycleObject.weekend;
//     } else if (selectedRate === 'combi') {
//       totalPrice = combiDays * selectedMotorcycleObject.combi + weeklyPeriods * selectedMotorcycleObject.weekend;
//     }

//     if (zusatzpaketChecked) {
//       totalPrice += 69;
//     }

//     return totalPrice;
//   };































//   const calculatePrice2 = (zusatzpaketChecked) => {
//     if (!startDate || !endDate) return 0;
//     const oneDay = 24 * 60 * 60 * 1000;
//     const days = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1 || 1;
//     const selectedMotorcycle2Object = motorcycles.find(motorcycle => motorcycle.Name === selectedMotorcycle2);
//     if (!selectedMotorcycle2Object) return 0;

//     let totalPrice = 0;

//     if (selectedRate === 'weekly') {
//       // Если выбрана опция weekly, то цена за всю неделю
//       totalPrice = selectedMotorcycle2Object.weekend;
//     } else if (selectedRate === 'combi') {
//       // Если выбрана опция combi, то цена за каждый день по тарифу combi
//       totalPrice = days * selectedMotorcycle2Object.combi;
//     } else {
//       // Иначе учитываем количество выбранных дней
//       let rate = selectedRate === 'daily' ? selectedMotorcycle2Object.daily_rate : selectedMotorcycle2Object.weekend;
//       totalPrice = days * rate;
//     }

//     if (zusatzpaketChecked) {
//       totalPrice += 69; // Добавляем 69 к общей сумме, если zusatzpaketChecked установлен
//     }

//     return totalPrice; // Возвращаем общую стоимость
//   };

//   // Функция для вычисления количества дней между датами
//   // const calculateDays = () => {
//   //   if (!startDate || !endDate) return 0; // Если начальная или конечная даты не выбраны, вернуть 0
//   //   // Преобразуем даты в строковый формат без времени
//   //   const startDateString = startDate.toISOString().slice(0, 10);
//   //   const endDateString = endDate.toISOString().slice(0, 10);
//   //   // Если начальная и конечная даты совпадают, вернуть 1, иначе вернуть разницу в днях + 1
//   //   return startDateString === endDateString ? 1 : Math.round((endDate - startDate) / (24 * 60 * 60 * 1000)) + 1;
//   // };

//   const calculateDays = () => {
//     if (!startDate || !endDate) return 0;
//     const oneDay = 24 * 60 * 60 * 1000;
//     return Math.round((endDate - startDate) / oneDay) + 1;
//   };


//   // Обновить количество дней и цену при изменении даты
//   useEffect(() => {
//     console.log('LOOOOOOP!!!!//////////////1');
//     setDays(calculateDays());
//     setPrice(calculatePrice(zusatzpaketChecked));
//     setPrice2(calculatePrice2(zusatzpaketChecked)); // Устанавливаем вторую цену
//     setPriceWithoutPaket(calculatePrice(false));
//     setPrice2WithoutPaket(calculatePrice2(false));

//   }, [startDate, endDate, startTime, endTime, selectedRate, selectedRate2, selectedMotorcycle, selectedMotorcycle2, zusatzpaketChecked]);

//   // Обработчик выбора новой начальной даты
//   const handleStartDateChange = (date) => {
//     setStartDate(date); // Установить новую начальную дату
//     setEndDate(null); // Сбросить конечную дату
//   };

//   const handleStartTimeChange = (time) => {
//     setStartTime(time);
//   };

//   const handleEndTimeChange = (time) => {
//     setEndTime(time);

//   };

//   const getNextFriday = (date) => {
//     const day = date.getDay();
//     const friday = 5; // Пятница
//     const daysToAdd = (friday + 7 - day) % 7; // Количество дней до следующей пятницы
//     const nextFriday = new Date(date);
//     nextFriday.setDate(date.getDate() + daysToAdd);
//     return nextFriday;
//   };

//   useEffect(() => {
//     console.log('LOOOOOOP!!!!');

//     if (startDate && selectedRate === 'weekly') {
//       // Если выбран startDate и тариф weekly, устанавливаем endDate через 3 дня
//       const newEndDate = new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000);
//       setEndDate(newEndDate);
//     }
//   }, [startDate, selectedRate]);

//   const startDateOptions = startDate && startDate.getDay() === 5 && selectedRate === 'combi' ? [
//     { value: new Date(startDate.setHours(8, 30)), label: '8:30' },
//     { value: new Date(startDate.setHours(15, 0)), label: '15:00' }
//   ] : [];

//   const endDateOptions = endDate && endDate.getDay() === 1 && selectedRate === 'combi' ? [
//     { value: new Date(endDate.setHours(10, 0)), label: '10:00' },
//     { value: new Date(endDate.setHours(17, 30)), label: '17:30' }
//   ] : [];

//   return (
//     <>
//       <div className='dpContainer'>
//         <div className='onepixel'>
//           <div>Übernahmedatum:</div>
//           <div className='datePicker'>
//             <div className='datePickerIn'>

//               <DatePicker
//                 onFocus={e => e.target.blur()}
//                 selected={startDate}
//                 onChange={handleStartDateChange}
//                 selectsStart
//                 startDate={startDate}
//                 endDate={endDate}
//                 filterDate={isWorkingDay}
//                 withPortal
//                 disabled={formattedStartDate !== null && formattedStartDate !== undefined}
//                 // minDate={new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)}
//                 minDate={today}
//                 dateFormat="dd.MM.yyyy"
//               />
//             </div>


//             <div className='timePicker1'>
//               <DatePicker
//                 selected={startTime}
//                 onChange={handleStartTimeChange}
//                 showTimeSelect
//                 showTimeSelectOnly
//                 timeIntervals={15}
//                 timeCaption="Time"
//                 dateFormat="HH:mm"
//                 disabled
//               />
//             </div>

//             {startDate && startDate.getDay() === 5 && selectedRate === 'combi' && (
//               <Select
//                 options={startDateOptions}
//                 onChange={(option) => setStartTime(option.value)}
//                 value={startDateOptions.find(option => option.value.getTime() === startTime.getTime())}
//               />
//             )}



//           </div>

//           <div>Rückgabedatum:</div>
//           <div className='datePicker'>
//             <div className='datePickerIn'>
//               <DatePicker
//                 onFocus={e => e.target.blur()}
//                 selected={endDate}
//                 onChange={(date) => setEndDate(date)}
//                 selectsEnd
//                 startDate={startDate}
//                 endDate={endDate}
//                 minDate={
//                   startDate && selectedRate === 'weekly'
//                     ? new Date(startDate.getTime() + 2 * 24 * 60 * 60 * 1000)
//                     : startDate
//                 }
//                 maxDate={
//                   startDate && selectedRate === 'weekly'
//                     ? new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000)
//                     : (startDate && selectedRate === 'daily'
//                       ? getNextFriday(startDate) // Следующий пятничный день от startDate 
//                       : null
//                     )
//                 }
//                 filterDate={isWorkingDay}
//                 withPortal
//                 disabled={(formattedEndDate !== null && formattedEndDate !== undefined) || // Если endDate уже установлен
//                   (!startDate) ||
//                   (selectedRate === 'weekly')// Или если startDate не выбран

//                 }
//                 dateFormat="dd.MM.yyyy"


//               />

//             </div>

//             <div className='timePicker1'>

//               <DatePicker
//                 selected={endTime}
//                 onChange={handleEndTimeChange}
//                 showTimeSelect
//                 showTimeSelectOnly
//                 timeIntervals={15}
//                 timeCaption="Time"
//                 dateFormat="HH:mm"
//                 disabled
//               />
//             </div>
//             {endDate && endDate.getDay() === 1 && selectedRate === 'combi' && (
//               <Select
//                 options={endDateOptions}
//                 onChange={(option) => setEndTime(option.value)}
//                 value={endDateOptions.find(option => option.value.getTime() === endTime.getTime())}
//               />
//             )}
//           </div>

//         </div>
//         <div className='timePickerContainer'>
//           {/* <div>ABHOLZEIT</div> */}


//           {/* <div>RÜCKGABEZEIT</div> */}


//         </div>
//       </div>
//     </>
//   );
// }

// export default CustomDatePicker;



// CustomDatePicker.jsx
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Form.css';

function CustomDatePicker({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  selectedRate,
  selectedRate2,
  selectedMotorcycle,
  selectedMotorcycle2,
  motorcycles,
  setDays,
  setPrice,
  setPrice2,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  formattedStartDate,
  formattedEndDate,
  zusatzpaketChecked,
  setPriceWithoutPaket,
  setPrice2WithoutPaket,
  setCombiDays,
  setWeeklyPeriods,
}) {
  const today = new Date();

  const isWorkingDay = (date) => {
    if (selectedRate === 'weekly') {
      const day = date.getDay();
      return day === 5; // Вернуть true только для пятницы
    }
    if (selectedRate === 'daily' || selectedRate === 'combi') {
      const day = date.getDay();
      return day !== 0 && day !== 6; // Вернуть true для всех дней кроме субботы и воскресенья
    }
    return true; // По умолчанию считаем, что день открыт
  };

  const calculatePrice = (zusatzpaketChecked) => {
    if (!startDate || !endDate) return 0;
    const totalDays = calculateDays(); // Используем функцию calculateDays для получения totalDays

    const { combiDays, weeklyPeriods } = countDaysInCombiPeriods(startDate, endDate, startTime, endTime);
    const selectedMotorcycleObject = motorcycles.find(motorcycle => motorcycle.Name === selectedMotorcycle);
    if (!selectedMotorcycleObject) return 0;

    let totalPrice = 0;

    if (selectedRate === 'daily') {
      const totalDays = Math.round(Math.abs((endDate - startDate) / (24 * 60 * 60 * 1000))) + 1;
      totalPrice = totalDays * selectedMotorcycleObject.daily_rate;
    } else if (selectedRate === 'weekly') {
      totalPrice = selectedMotorcycleObject.weekend;
    } else if (selectedRate === 'combi') {
      totalPrice = combiDays * selectedMotorcycleObject.combi + weeklyPeriods * selectedMotorcycleObject.weekend;
    }

    if (zusatzpaketChecked) {
      totalPrice += 69;
    }

    return totalPrice;
  };

  const calculatePrice2 = (zusatzpaketChecked) => {
    if (!startDate || !endDate) return 0;
    const totalDays = calculateDays(); // Используем функцию calculateDays для получения totalDays

    const { combiDays, weeklyPeriods } = countDaysInCombiPeriods(startDate, endDate, startTime, endTime);
    const selectedMotorcycle2Object = motorcycles.find(motorcycle => motorcycle.Name === selectedMotorcycle2);
    if (!selectedMotorcycle2Object) return 0;

    let totalPrice = 0;

    if (selectedRate === 'daily') {
      const totalDays = Math.round(Math.abs((endDate - startDate) / (24 * 60 * 60 * 1000))) + 1;
      totalPrice = totalDays * selectedMotorcycle2Object.daily_rate;
    } else if (selectedRate === 'weekly') {
      totalPrice = selectedMotorcycle2Object.weekend;
    } else if (selectedRate === 'combi') {
      totalPrice = combiDays * selectedMotorcycle2Object.combi + weeklyPeriods * selectedMotorcycle2Object.weekend;
    }

    if (zusatzpaketChecked) {
      totalPrice += 69;
    }

    return totalPrice;
  };

  const calculateDays = () => {
    if (!startDate || !endDate) return 0;
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round((endDate - startDate) / oneDay) + 1;
  };

  const countDaysInCombiPeriods = (startDate, endDate, startTime, endTime) => {
    if (!startDate || !endDate) {
      return null;
    }

    const oneDay = 24 * 60 * 60 * 1000;
    let periods = { combiDays: 0, weeklyPeriods: 0 };

    let currentStartDate = new Date(startDate); // создаем копию startDate
    let currentEndDate = new Date(endDate); // создаем копию endDate

    currentStartDate.setHours(0, 0, 0, 0);
    currentEndDate.setHours(23, 59, 59, 999); // Включаем весь день завершения

    if (currentStartDate.toDateString() === currentEndDate.toDateString()) {
      const day = currentStartDate.getDay();
      if (day >= 2 && day <= 4) { // Вт - Чт
        periods.combiDays++;
      } else if (day === 5) { // Пт
        if (startTime && startTime.getHours() === 8 && startTime.getMinutes() === 30) {
          periods.combiDays++;
        }
      } else if (day === 1) { // Пн
        if (endTime && endTime.getHours() === 17 && endTime.getMinutes() === 30) {
          periods.combiDays++;
        }
      }
      console.log(`Date: ${currentStartDate}, Day: ${day}, CombiDays: ${periods.combiDays}, WeeklyPeriods: ${periods.weeklyPeriods}`);

      return periods;
    }

    const weeksCounted = new Set(); // Set для отслеживания уникальных недель, в которых уже считались выходные

    while (currentStartDate <= currentEndDate) {
      const day = currentStartDate.getDay();
      const weekNumber = getWeekNumber(currentStartDate);

      // Обработка вторника, среды и четверга
      if (day >= 2 && day <= 4) {
        periods.combiDays++;
      }

      // Обработка пятницы
      if (day === 5) {
        if (currentStartDate.toDateString() === startDate.toDateString()) {
          if (startTime && startTime.getHours() === 8 && startTime.getMinutes() === 30) {
            periods.combiDays++;
          }
        } else {
          periods.combiDays++;
        }
      }


      // Обработка субботы
      if (day === 6 && !weeksCounted.has(weekNumber)) {
        periods.weeklyPeriods++;
        weeksCounted.add(weekNumber);
      }

      // Обработка воскресенья (считаем только если суббота не была обработана)
      // if (day === 0 && !weeksCounted.has(weekNumber)) {
      //   periods.weeklyPeriods++;
      //   weeksCounted.add(weekNumber);
      // }

      // Обработка понедельника
      if (day === 1) {
        if (currentStartDate.toDateString() === endDate.toDateString()) {
          if (endTime && endTime.getHours() === 17 && endTime.getMinutes() === 30) {
            periods.combiDays++;
          }
        } else {
          periods.combiDays++;
        }
      }



      // Debug output for each iteration
      console.log(`Date: ${currentStartDate}, Day: ${day}, CombiDays: ${periods.combiDays}, WeeklyPeriods: ${periods.weeklyPeriods}`);

      currentStartDate.setDate(currentStartDate.getDate() + 1);
    }

    return periods;
  };


  // Вспомогательная функция для получения номера недели
  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;

    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };





  useEffect(() => {
    console.log('LOOOOOOP!!!!//////////////1');
    setDays(calculateDays());
    setPrice(calculatePrice(zusatzpaketChecked));
    setPrice2(calculatePrice2(zusatzpaketChecked)); // Устанавливаем вторую цену
    setPriceWithoutPaket(calculatePrice(false));
    setPrice2WithoutPaket(calculatePrice2(false));
  }, [startDate, endDate, startTime, endTime, selectedRate, selectedRate2, selectedMotorcycle, selectedMotorcycle2, zusatzpaketChecked]);

  useEffect(() => {
    console.log('LOOOOOOP!!!!');

    if (startDate && selectedRate === 'weekly') {
      // Если выбран startDate и тариф weekly, устанавливаем endDate через 3 дня
      const newEndDate = new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000);
      setEndDate(newEndDate);
    }
  }, [startDate, selectedRate]);

  useEffect(() => {
    if (startDate && endDate) {
      const { combiDays, weeklyPeriods } = countDaysInCombiPeriods(startDate, endDate, startTime, endTime);
      console.log(`Final result - CombiDays: ${combiDays}, WeeklyPeriods: ${weeklyPeriods}`);

      // Обновляем состояние в родительском компоненте
      setCombiDays(combiDays);
      setWeeklyPeriods(weeklyPeriods);
    }
  }, [startDate, endDate, startTime, endTime, selectedRate]);

  const handleStartDateChange = (date) => {
    setStartDate(date); // Установить новую начальную дату

    // Устанавливаем время для startDate на 08:30
    const newStartTime = new Date(date);
    const newEndTime = new Date(date);
    
    if (selectedRate === 'weekly') {
      newStartTime.setHours(15, 0, 0); // Установить часы и минуты для weekly
      newEndTime.setHours(10, 0, 0); // Установить часы и минуты для weekly
  } else {
      newStartTime.setHours(8, 30, 0); // Установить часы и минуты по умолчанию
      newEndTime.setHours(17, 30, 0); // Установить часы и минуты по умолчанию
  }
    setStartTime(newStartTime); // Обновить состояние startTime
    setEndTime(newEndTime); // Обновить состояние startTime

    setEndDate(null); // Сбросить конечную дату
  };

  const handleEndDateChange = (date) => {
    setEndDate(date); // Установить новую начальную дату

    // Устанавливаем время для startDate на 08:30
    const newEndTime = new Date(date);
    newEndTime.setHours(17, 30, 0); // Установить часы и минуты
    setEndTime(newEndTime); // Обновить состояние startTime


  };


  const handleStartTimeChange = (time) => {
    if (startDate) {
      const [hours, minutes] = time.split(':').map(Number);
      const newStartTime = new Date(startDate);
      newStartTime.setHours(hours);
      newStartTime.setMinutes(minutes);
      setStartTime(newStartTime);
    }
  };

  const handleEndTimeChange = (time) => {
    if (endDate) {
      const [hours, minutes] = time.split(':').map(Number);
      const newEndTime = new Date(endDate);
      newEndTime.setHours(hours);
      newEndTime.setMinutes(minutes);
      setEndTime(newEndTime);
    }
  };

  const getNextFriday = (date) => {
    const day = date.getDay();
    const friday = 5; // Пятница
    const daysToAdd = (friday + 7 - day) % 7; // Количество дней до следующей пятницы
    const nextFriday = new Date(date);
    nextFriday.setDate(date.getDate() + daysToAdd);
    return nextFriday;
  };

  useEffect(() => {
    console.log('LOOOOOOP!!!!');

    if (startDate && selectedRate === 'weekly') {
      // Если выбран startDate и тариф weekly, устанавливаем endDate через 3 дня
      const newEndDate = new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000);
      setEndDate(newEndDate);
    }
  }, [startDate, selectedRate]);


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      transform: 'scale(0.7)', // Уменьшает размер компонента
      transformOrigin: 'top left', // Устанавливает точку начала масштабирования
      width: '125%',
      backgroundColor: 'white', // Фон
      borderColor: state.isFocused ? '#2684FF' : '#ccc', // Цвет рамки при фокусе и без
      boxShadow: state.isFocused ? '0 0 0 1px #2684FF' : 'none', // Тень при фокусе
      '&:hover': {
        borderColor: '#2684FF' // Цвет рамки при наведении
      },
    }),
    menu: provided => ({
      ...provided,
      zIndex: 9999, // Высота для перекрытия других элементов
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#2684FF' : 'white', // Фон для выбранного элемента
      color: state.isSelected ? 'white' : 'black', // Цвет текста для выбранного элемента
      '&:hover': {
        backgroundColor: '#2684FF', // Фон при наведении
        color: 'white' // Цвет текста при наведении
      }
    }),
    placeholder: provided => ({
      ...provided,
      color: '#aaa' // Цвет текста плейсхолдера
    })
  };

  const renderStartTimeSelect = () => {
    if (startDate && endDate && startDate.toDateString() === endDate.toDateString()) {
      return null; // Скрываем селект, если даты совпадают
    }
    if (startDate && startDate.getDay() === 5 && selectedRate === 'combi') { // 5 - пятница
      return (
        <Select
          options={[
            { value: '08:30', label: '08:30' },
            { value: '15:00', label: '15:00' },
          ]}
          defaultValue={{ value: '08:30', label: '08:30' }}
          onChange={option => handleStartTimeChange(option.value)}
          styles={customStyles}
        />
      );
    }
    return null;
  };

  const renderEndTimeSelect = () => {
    if (startDate && endDate && startDate.toDateString() === endDate.toDateString()) {
      return null; // Скрываем селект, если даты совпадают
    }
    if (endDate && endDate.getDay() === 1 && selectedRate === 'combi') { // 1 - понедельник
      return (
        <Select
          options={[
            { value: '17:30', label: '17:30' },
            { value: '10:00', label: '10:00' },
          ]}
          defaultValue={{ value: '17:30', label: '17:30' }}
          onChange={option => handleEndTimeChange(option.value)}
          styles={customStyles}

        />
      );
    }
    return null;
  };

  return (
    <>
      <div className='dpContainer'>
        <div className='onepixel'>
          <div style={{ display: 'flex', gap: '137px' }}>
            <div>Übernahmedatum:</div>
            {startDate && startDate.getDay() === 5 && selectedRate === 'combi' && startDate && String(startDate) !== String(endDate) && (
              <div style={{ fontSize: '10px' }}>Bitte wählen sie die Zeit aus</div>
            )}
          </div>


          <div className='datePicker'>
            <div className='datePickerIn'>
              <DatePicker
                onFocus={e => e.target.blur()}
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                filterDate={isWorkingDay}
                withPortal
                disabled={formattedStartDate !== null && formattedStartDate !== undefined}
                minDate={today}
                dateFormat="dd.MM.yyyy"
              />
            </div>

            <div className='timePicker1'>
              {/* Селект времени */}
              <DatePicker
                selected={startTime}
                onChange={handleStartTimeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="HH:mm"
                disabled
              />

            </div>

            {renderStartTimeSelect()}

          </div>
          <div style={{ display: 'flex', gap: '148px' }}>
            <div>Rückgabedatum:</div>
            {endDate && endDate.getDay() === 1 && selectedRate === 'combi' && endDate && String(startDate) !== String(endDate) && (
              <div style={{ fontSize: '10px' }}>Bitte wählen sie die Zeit aus</div>
            )}
          </div>
          <div className='datePicker'>
            <div className='datePickerIn'>
              <DatePicker
                onFocus={e => e.target.blur()}
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={
                  startDate && selectedRate === 'weekly'
                    ? new Date(startDate.getTime() + 2 * 24 * 60 * 60 * 1000)
                    : startDate
                }
                maxDate={
                  startDate && selectedRate === 'weekly'
                    ? new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000)
                    : (startDate && selectedRate === 'daily'
                      ? getNextFriday(startDate) // Следующий пятничный день от startDate 
                      : null
                    )
                }
                filterDate={isWorkingDay}
                withPortal
                disabled={(formattedEndDate !== null && formattedEndDate !== undefined) ||
                  (!startDate) ||
                  (selectedRate === 'weekly')}
                dateFormat="dd.MM.yyyy"
              />
            </div>

            <div className='timePicker1'>
              {/* Селект времени */}
              <DatePicker
                selected={endTime}
                onChange={handleEndTimeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="HH:mm"
                disabled
              />
            </div>
            {renderEndTimeSelect()}

          </div>
        </div>
      </div>
    </>
  );
}

export default CustomDatePicker;













