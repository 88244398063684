import React, { useState, useEffect, useRef } from 'react';
import './Game.css';
import motorcycleImgSrc from '../../assets/car1.png'; // Изображение мотоцикла
import car2ImgSrc from '../../assets/car2.png';
import car3ImgSrc from '../../assets/car3.png';
import car4ImgSrc from '../../assets/car4.png';
import car5ImgSrc from '../../assets/car5.png';
import car6ImgSrc from '../../assets/car6.png';
import car7ImgSrc from '../../assets/car7.png';
import car8ImgSrc from '../../assets/car8.png';
import car9ImgSrc from '../../assets/car9.png';
import car10ImgSrc from '../../assets/car10.png';
import carIntro from '../../assets/carIntro.png';
import roadImgSrc from '../../assets/road2.png';


const canvasWidth = 380;
//480
const canvasHeight = 640;
const bikeWidth = 40;
const bikeHeight = 80;
const totalLanes = 6;
const laneWidth = canvasWidth / totalLanes;
const carWidth = 50;
const carHeight = 80;
const heartSize = 30;
const initialCarSpeeds = [2, 3, 4]; // Начальные скорости машин
const frameRate = 1000 / 60;
const scoreToLevelUp = 30;
const scoreToAddHeart = 40;
const roadSpeed = 8; // Скорость дороги


const carImages = [car2ImgSrc, car3ImgSrc, car4ImgSrc, car5ImgSrc, car6ImgSrc, car7ImgSrc, car8ImgSrc, car9ImgSrc, car10ImgSrc];

const Game = () => {
  const canvasRef = useRef(null);
  const [bikeLane, setBikeLane] = useState(2);
  const [cars, setCars] = useState([]);
  const [hearts, setHearts] = useState([]);
  const [score, setScore] = useState(0);
  const [lives, setLives] = useState(3);
  const [isGameOver, setIsGameOver] = useState(false);
  const [lastScoreUpdate, setLastScoreUpdate] = useState(0);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [showLevelText, setShowLevelText] = useState(false);
  const [level, setLevel] = useState(1);
  const [carSpeeds, setCarSpeeds] = useState(initialCarSpeeds);
  const [roadY, setRoadY] = useState(0); // Позиция дороги
  const [roadSpeed, setRoadSpeed] = useState(8); // Начальная скорость дороги
  const roadSpeedIncrement = 1.5; // Прирост скорости дороги при каждом уровне


  const motorcycleImg = useRef(new Image());
  motorcycleImg.current.src = motorcycleImgSrc;

  const carImgs = useRef(carImages.map(src => {
    const img = new Image();
    img.src = src;
    return img;
  }));

  const roadImg = useRef(new Image());
  roadImg.current.src = roadImgSrc;


  useEffect(() => {
    if (!isGameStarted) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let lastTime = 0;

    const updateGame = (time) => {
      if (isGameOver) return;

      const deltaTime = time - lastTime;
      if (deltaTime < frameRate) {
        animationFrameId = requestAnimationFrame(updateGame);
        return;
      }
      lastTime = time;

      ctx.clearRect(0, 0, canvasWidth, canvasHeight);

      // Обновление позиции дороги
      setRoadY(prevRoadY => {
        const newRoadY = prevRoadY + roadSpeed;
        return newRoadY >= canvasHeight ? 0 : newRoadY;
      });

      // Нарисовать дорогу
      ctx.drawImage(roadImg.current, 0, roadY, canvasWidth, canvasHeight);
      ctx.drawImage(roadImg.current, 0, roadY - canvasHeight, canvasWidth, canvasHeight);

      // Нарисовать мотоцикл
      ctx.drawImage(motorcycleImg.current, bikeLane * laneWidth + (laneWidth - bikeWidth) / 2, canvasHeight - bikeHeight - 20, bikeWidth, bikeHeight);

      // Нарисовать машины
      cars.forEach(car => {
        const carImg = carImgs.current[car.imgIndex];
        ctx.drawImage(carImg, car.lane * laneWidth + (laneWidth - carWidth) / 2, car.y, carWidth, carHeight);
      });

      // Нарисовать "сердечки"
      ctx.fillStyle = '#f00';
      ctx.font = `${heartSize}px Arial`;
      hearts.forEach(heart => {
        ctx.fillText('❤️', heart.lane * laneWidth + (laneWidth - heartSize) / 2, heart.y + heartSize / 2);
      });

      // Обновить позиции машин
      setCars(prevCars => {
        const updatedCars = prevCars.map(car => {
          const speed = carSpeeds[car.speedIndex];
          return { ...car, y: car.y + speed };
        });
        return updatedCars.filter(car => car.y < canvasHeight);
      });

      // Обновить позиции "сердечек"
      setHearts(prevHearts => {
        const updatedHearts = prevHearts.map(heart => ({ ...heart, y: heart.y + carSpeeds[0] }));
        return updatedHearts.filter(heart => heart.y < canvasHeight);
      });

      // Добавить новые машины
      if (Math.random() < 0.02 + (level - 1) * 0.01) {
        const availableLanes = Array.from({ length: totalLanes }, (_, i) => i);
        const occupiedLanes = cars.map(car => car.lane);
        const freeLanes = availableLanes.filter(lane => !occupiedLanes.includes(lane));

        if (freeLanes.length > 0) {
          const lane = freeLanes[Math.floor(Math.random() * freeLanes.length)];
          const speedIndex = Math.floor(Math.random() * carSpeeds.length);
          const imgIndex = Math.floor(Math.random() * carImgs.current.length); // Выбор случайного изображения
          setCars(prevCars => [
            ...prevCars,
            { lane, y: -carHeight, speedIndex, imgIndex }
          ]);
        }
      }

      // Добавить новые "сердечки"
      if (score % scoreToAddHeart === 0 && score > 0 && hearts.length === 0) {
        const occupiedLanes = cars.map(car => car.lane);
        const availableLanes = Array.from({ length: totalLanes }, (_, i) => i).filter(lane => !occupiedLanes.includes(lane));

        if (availableLanes.length > 0) {
          let heartLane;
          do {
            heartLane = availableLanes[Math.floor(Math.random() * availableLanes.length)];
          } while (cars.some(car => car.lane === heartLane && car.y < canvasHeight / 2));

          setHearts(prevHearts => [
            ...prevHearts,
            { lane: heartLane, y: -heartSize }
          ]);
        }
      }

      // Проверить столкновения с машинами
      let collisionDetected = false;
      setCars(prevCars => {
        const filteredCars = prevCars.filter(car => {
          if (car.lane === bikeLane && car.y + carHeight > canvasHeight - bikeHeight - 20) {
            if (!collisionDetected) {
              setLives(prevLives => {
                const newLives = Math.max(prevLives - 1, 0);
                if (newLives <= 0) {
                  setIsGameOver(true);
                }
                return newLives;
              });
              collisionDetected = true; // Зафиксировать столкновение
            }
            return false; // Удалить машину, с которой произошло столкновение
          }
          return true;
        });
        return filteredCars;
      });

      // Проверить столкновения с "сердечками"
      let heartCaught = false;
      setHearts(prevHearts => {
        const filteredHearts = prevHearts.filter(heart => {
          if (heart.lane === bikeLane && heart.y + heartSize > canvasHeight - bikeHeight - 20) {
            if (!heartCaught) {
              setLives(prevLives => {
                const newLives = Math.min(prevLives + 1, 6);
                return newLives;
              });
              heartCaught = true; // Зафиксировать захват "сердечка"
            }
            return false; // Удалить "сердечко"
          }
          return true;
        });
        return filteredHearts;
      });

      // Обновить счет каждые секунду
      if (time - lastScoreUpdate >= 1000) {
        setScore(prevScore => {
          const newScore = prevScore + 1;

          // Обновление уровня каждые 30 очков
          if (newScore % scoreToLevelUp === 0) {
            const newLevel = Math.floor(newScore / scoreToLevelUp) + 1;
            setLevel(newLevel);
            const newSpeeds = carSpeeds.map(speed => speed + 1.5);
            setCarSpeeds(newSpeeds);
            setRoadSpeed(prevRoadSpeed => prevRoadSpeed + roadSpeedIncrement); // Увеличение скорости дороги
            setShowLevelText(true);
            setTimeout(() => {
              setShowLevelText(false);
            }, 3000);
          }

          return newScore;
        });
        setLastScoreUpdate(time);
      }

      // Запрос следующего кадра
      animationFrameId = requestAnimationFrame(updateGame);
    };

    animationFrameId = requestAnimationFrame(updateGame);

    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft' && bikeLane > 0) {
        setBikeLane(prevLane => prevLane - 1);
      } else if (e.key === 'ArrowRight' && bikeLane < totalLanes - 1) {
        setBikeLane(prevLane => prevLane + 1);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      cancelAnimationFrame(animationFrameId);
    };
  }, [isGameStarted, isGameOver, bikeLane, cars, hearts, lastScoreUpdate, level, carSpeeds, score, lives]);

  const startGame = () => {
    setIsGameStarted(true);
    setIsGameOver(false);
    setScore(0);
    setLives(3);
    setCars([]);
    setHearts([]);
    setBikeLane(2);
    setLevel(1);
    setCarSpeeds(initialCarSpeeds);
    setRoadSpeed(8); // Установите начальную скорость дороги

    setShowLevelText(true);

    setTimeout(() => {
      setShowLevelText(false);
    }, 3000);
  };

  const tryAgain = () => {
    startGame();
  };

  const handleSliderChange = (event) => {
    const lane = Math.floor(event.target.value);
    setBikeLane(lane);
  };

  return (

    <div className="game-container">
      {!isGameStarted && (
        <>
          <button className="start-button" onClick={startGame}>START GAME</button>
          <img style={{ width: '359px', position: 'absolute', left: '12px', top: '210px' }} src={carIntro} alt="" />
        </>

      )}
      <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight}></canvas>
      <div className="game-info">
        <div className="score">Score: {score}</div>
        <div className="lives">Lives: {'❤️'.repeat(Math.min(lives, 6))}</div>
        {showLevelText && <div className="level-text">LEVEL {level}</div>}
        {isGameOver && (
          <div className="game-over">
          <img style={{ width: '359px', position: 'absolute', left: '-180px', top: '210px', zIndex:'-1' }} src={carIntro} alt="" />
          <div>Game Over</div>
            <button className="retry-button" onClick={tryAgain}>Try Again</button>
          </div>
        )}
      </div>
      <div className="slider-container">
        <input
          type="range"
          min="0"
          max={totalLanes - 1}
          value={bikeLane}
          onChange={handleSliderChange}
          className="slider2"
          step="1"
        />
      </div>
    </div>
  );
};

export default Game;
