import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import './Login.css'

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false);

  useEffect(() => {
    // Проверяем, залогинен ли пользователь
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      setLoginSuccess(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://admin.motorrad-mieten-kaernten.at/auth/login', {
        email,
        password
      });
  
      // Получаем токен доступа из ответа на запрос
      const accessToken = response.data.data.access_token;
      // Сохраняем токены доступа в локальное хранилище
      sessionStorage.setItem('accessToken', accessToken);
      sessionStorage.setItem('refreshToken', response.data.data.refresh_token);

      // Получаем идентификатор пользователя из токена доступа
      const userId = getUserIdFromToken(accessToken);
      // Проверяем, если userId успешно извлечен
      if (userId) {
        // Перенаправляем пользователя на главную страницу
        setLoginSuccess(true);
        const userData = await fetchUserData(userId);
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('userData', JSON.stringify(userData));
        onLogin(userData);
      } else {
        console.error('User ID not found in JWT payload');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Incorrect email or password');
    }
  };

  const getUserIdFromToken = (token) => {
    try {
      // Добавляем проверку на пустоту токена
      if (!token) {
        console.error('JWT token is empty');
        return null;
      }
  
      // Разделяем токен на три части (заголовок, тело, подпись)
      const tokenParts = token.split('.');
      if (tokenParts.length !== 3) {
        console.error('Invalid JWT token format');
        return null;
      }
  
      // Получаем тело токена (вторая часть) и декодируем его из base64
      const jsonPayload = atob(tokenParts[1]);
      console.log('JSON Payload:', jsonPayload); // Переместили вывод внутрь блока try
  
      const payload = JSON.parse(jsonPayload);
      // Проверяем, что тело токена содержит идентификатор пользователя
      if (!payload || !payload.id) {
        console.error('User ID not found in JWT payload');
        return null;
      }
  
      // Возвращаем идентификатор пользователя
      return payload.id;
    } catch (error) {
      console.error('Error parsing JWT:', error);
      return null;
    }
  };

  const fetchUserData = async (userId) => {
    try {
      const response = await axios.get(`https://admin.motorrad-mieten-kaernten.at/users/${userId}`);
      return response.data; // Возвращаем данные пользователя
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

  if (loginSuccess) {
    return <Navigate to="/orders/list" />;
  }
  
  return (
    <div className="login-container">
      <h2 className="login-title">Admin Panel</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label">Email:</label>
          <input className="form-input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="form-group">
          <label className="form-label">Password:</label>
          <input className="form-input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button className="submit-btn" type="submit">Login</button>
        {error && <div className="error-msg">{error}</div>}
      </form>
    </div>
  );
};

export default Login;