import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getYear, getMonth } from 'date-fns';

const BirthdayDatePicker = ({ selectedDate2, setSelectedDate2, handleInputChange2 }) => {
  const [startDate, setStartDate] = useState(selectedDate2);
  const today = new Date();
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(today.getFullYear() - 18);

  const years = [];
  const currentYear = getYear(new Date());
  for (let year = 1900; year <= currentYear; year++) {
    years.push(year);
  }

  const months = [
    "Januar", "Februar", "März", "April", "Mai", "Juni",
    "Juli", "August", "September", "Oktober", "November", "Dezember"
  ];


  const handleChange2 = (date) => {
    setStartDate(date);
    setSelectedDate2(date); // Передаем выбранную дату обратно в родительский компонент
    if (handleInputChange2) {
      handleInputChange2(date); // Вызываем обработчик изменения, если он передан
  }
  };

  return (
    <div>
      <DatePicker
        renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (

          <div className='birthday' style={{ margin: 10, display: "flex", justifyContent: "center" }}>
            {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button> */}
            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
              {years.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
            <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
              {months.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
            {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button> */}

          </div>
        )}
        selected={startDate}
        onChange={handleChange2}
        withPortal
        maxDate={eighteenYearsAgo}
        dateFormat="dd.MM.yyyy"
        // required
      />
    </div>

  );
};

export default BirthdayDatePicker;
