import React, { useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { getRestaurants } from '../../services/apiService';
import './Restaurants.css'; // Импорт CSS файла


mapboxgl.accessToken = 'pk.eyJ1IjoibGV5cHVzLWNvZGUiLCJhIjoiY2x1bXAwdmh6MGEyMTJrcG54djk0bDJjeiJ9.lZe5s7XCNn3dndXXl66Naw';

function Restaurants() {
    const [restaurants, setRestaurants] = useState([]);

    useEffect(() => {
        const fetchRestaurants = async () => {
            try {
                const restaurantsData = await getRestaurants();
                setRestaurants(restaurantsData.data);
            } catch (error) {
                console.error('Error fetching restaurants:', error);
            }
        };

        fetchRestaurants();
    }, []);

    const StarRating = ({ rating }) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(<span key={i} className="star filled">&#9733;</span>);
            } else {
                stars.push(<span key={i} className="star">&#9733;</span>);
            }
        }
        return <div>{stars}</div>;
    };

    useEffect(() => {
        if (Array.isArray(restaurants)) {
            restaurants.forEach(restaurant => {
                const map = new mapboxgl.Map({
                    container: `map-${restaurant.id}`,
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: restaurant.location.coordinates,
                    zoom: 12
                });

                new mapboxgl.Marker()
                    .setLngLat(restaurant.location.coordinates)
                    .addTo(map)
                    .setPopup(new mapboxgl.Popup().setHTML(`<p>${restaurant.name}</p><p>Addresse: ${restaurant.address}</p><p><a href="${restaurant.contact_info}" target='blank'>Webseite Besuchen</a></p>`));
            });
        }
    }, [restaurants]);

    return (
        <div className="page-container" id='unterkünfte'>
            <div className="content">
                <div style={{textAlign: 'center', marginBottom: '25px'}}>
                    <h1>Die beliebtesten Kärntner Motorrad-Unterkünfte!</h1>
                </div>
                <div className="restaurant-container">
                    {Array.isArray(restaurants) && restaurants.map(restaurant => (
                        <div key={restaurant.id} className="restaurant">
                            <div>
                                <h2 className="restaurant-name">{restaurant.name}</h2>
                                <img className='restaurantImg' src={`https://admin.motorrad-mieten-kaernten.at/assets/${restaurant.image}`} alt="" />
                                <StarRating rating={restaurant.rating} />
                            </div>

                            <h4 className="restaurant-address">{restaurant.address}</h4>

                            <div id={`map-${restaurant.id}`} className="map-container"></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Restaurants;
