import React from 'react';
import './Header.css';
import mapImg from '../../assets/map_web.jpg';
import logo from '../../assets/megabike_logo.png';
import img1 from '../../assets/1.jpg';
import img2 from '../../assets/2.jpg';
import img3 from '../../assets/3.jpg';

const Header = () => {


    function smoothScroll(target) {
        const element = document.getElementById(target);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: "smooth"
            });
        }
    }


    return (
        <div>
            <header className="header">
                <div className="header-left">
                    <p className='headText'><b>Frühstücken</b> in Italien.</p>
                    <p className='headText'><b>Mittagessen</b> in Slowenien.</p>
                    <p className='headText'><b>Abendessen</b> in Österreich.</p>
                    <img className='logo' src={logo} alt="Right Section Image" />

                </div>
                <div className="header-right">

                    <img src={mapImg} alt="Right Section Image" />
                </div>
            </header>
            <div className='links'>
                <div className="link" onClick={() => smoothScroll('motorcycles')}>
                    <img className='img1' src={img1} alt="" />
                    <span className='linkText'>Miet-Motorräder</span>
                    <hr className='hr'/>
                </div>
                <div className="link" onClick={() => smoothScroll('unterkünfte')}>
                    <img className='img2' src={img2} alt="" />
                    <span>Unterkünfte</span>
                    <hr className='hr'/>
                </div>
                <div className="link" onClick={() => smoothScroll('touren')}>
                    <img className='img3' src={img3} alt="" />
                    <span>Touren</span>
                    <hr className='hr'/>
                </div>
            </div>


        </div>


    );
};

export default Header;
