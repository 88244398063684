import React from 'react';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import imageUrl1 from '../../assets/mot.png';
import imageUrl2 from '../../assets/circle.png';


const generateInteractivePDF = async (orderData) => {
    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();

    // Add the first page
    const firstPage = pdfDoc.addPage();
    await addFirstPageContent(pdfDoc, firstPage, orderData); // Pass pdfDoc to addFirstPageContent

    // Save the PDF to a blob
    const pdfBytes = await pdfDoc.save();

    // Optionally, you can save the PDF to a file or send it to the client
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    // window.open(url, '_blank');
    window.open(url, 'pdfWindow', 'width=800,height=600');
};

const addFirstPageContent = async (pdfDoc, page, orderData) => {
    // Embed a standard font
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);



    page.drawRectangle({
        x: 400,
        y: page.getHeight() - 70,
        width: 110,
        height: 33,
        borderColor: rgb(0.7, 0.7, 0.7), // Use light gray color for the border
        borderWidth: 0.01, // Set border thickness
    });

    page.drawText('Kennzeichen', {
        x: 405,
        y: page.getHeight() - 67, // Adjust y position to be inside the border
        size: 8,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });


    {
        orderData.statusMotorcycle1 && (
            page.drawText(orderData.motorcycle1.kennzeichen, {
                x: 405,
                y: page.getHeight() - 55, // Adjust y position to be inside the border
                size: 14,
                font: font, // Use the embedded font
                color: rgb(0, 0, 0), // Use the gray color for the text
            })
        )
    }

    {
        orderData.statusMotorcycle2 && (
            page.drawText(orderData.motorcycle2.kennzeichen, {
                x: 405,
                y: page.getHeight() - 55, // Adjust y position to be inside the border
                size: 14,
                font: font, // Use the embedded font
                color: rgb(0, 0, 0), // Use the gray color for the text
            })
        )
    }


    page.drawText('Motorrad Ubernahme', {
        x: 40,
        y: page.getHeight() - 50, // Позиция текста
        size: 12,
        font: fontBold, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
    });

    page.drawText('Bezeichen Sie durch einen Pfeil den Punkt sichtbarer Mängel', {
        x: 40,
        y: page.getHeight() - 65, // Позиция текста
        size: 9,
        font: font, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
    });




    const imageResponse1 = await fetch(imageUrl1);
    const imageBytes1 = await imageResponse1.arrayBuffer();

    // Embed the image in the PDF
    const image1 = await pdfDoc.embedPng(imageBytes1);

    // Draw the image on the PDF page
    page.drawImage(image1, {
        x: 330,
        y: 570,
        width: 230,
        height: 200,
    });




    page.drawRectangle({
        x: 40,
        y: page.getHeight() - 300,
        width: 160,
        height: 33,
        borderColor: rgb(0.5, 0.5, 0.5), // Use light gray color for the border
        borderWidth: 0.01, // Set border thickness
    });

    page.drawText('Ort/Datum der Übernahme', {
        x: 45,
        y: page.getHeight() - 297, // Adjust y position to be inside the border
        size: 8,
        font: font, // Use the embedded font
        color: rgb(0.5, 0.5, 0.5), // Use the gray color for the text
    });



    page.drawRectangle({
        x: 210,
        y: page.getHeight() - 300,
        width: 110,
        height: 33,
        borderColor: rgb(0.5, 0.5, 0.5), // Use light gray color for the border
        borderWidth: 0.01, // Set border thickness
    });

    page.drawText('Uhrzeit', {
        x: 215,
        y: page.getHeight() - 297, // Adjust y position to be inside the border
        size: 8,
        font: font, // Use the embedded font
        color: rgb(0.5, 0.5, 0.5), // Use the gray color for the text
    });

    page.drawRectangle({
        x: 330,
        y: page.getHeight() - 300,
        width: 110,
        height: 33,
        borderColor: rgb(0.5, 0.5, 0.5), // Use light gray color for the border
        borderWidth: 0.01, // Set border thickness
    });

    page.drawText('KM-Stand', {
        x: 335,
        y: page.getHeight() - 297, // Adjust y position to be inside the border
        size: 8,
        font: font, // Use the embedded font
        color: rgb(0.5, 0.5, 0.5), // Use the gray color for the text
    });



    page.drawText('Tank Füllstand', {
        x: 445,
        y: page.getHeight() - 274, // Adjust y position to be inside the border
        size: 8,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });


    const imageResponse2 = await fetch(imageUrl2);
    const imageBytes2 = await imageResponse2.arrayBuffer();

    // Embed the image in the PDF
    const image2 = await pdfDoc.embedPng(imageBytes2);

    // Draw the image on the PDF page
    page.drawImage(image2, {
        x: 445,
        y: page.getHeight() - 300,
        width: 20,
        height: 20,
    });

    page.drawText('leer', {
        x: 449,
        y: page.getHeight() - 292, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawImage(image2, {
        x: 466,
        y: page.getHeight() - 300,
        width: 20,
        height: 20,
    });

    page.drawText('1/4', {
        x: 471,
        y: page.getHeight() - 292, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawImage(image2, {
        x: 487,
        y: page.getHeight() - 300,
        width: 20,
        height: 20,
    });

    page.drawText('1/2', {
        x: 492,
        y: page.getHeight() - 292, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawImage(image2, {
        x: 508,
        y: page.getHeight() - 300,
        width: 20,
        height: 20,
    });

    page.drawText('3/4', {
        x: 513,
        y: page.getHeight() - 292, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawImage(image2, {
        x: 530,
        y: page.getHeight() - 300,
        width: 20,
        height: 20,
    });

    page.drawText('voll', {
        x: 534,
        y: page.getHeight() - 292, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });



    page.drawText('Unterschrift Vertreter Händler', {
        x: 42,
        y: page.getHeight() - 358, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawLine({
        start: { x: 40, y: page.getHeight() - 350 },
        end: { x: 295, y: page.getHeight() - 350 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });

    page.drawText('Unterschrift Benutzer', {
        x: 307,
        y: page.getHeight() - 358, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawLine({
        start: { x: 305, y: page.getHeight() - 350 },
        end: { x: 555, y: page.getHeight() - 350 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });

    // page.drawText(`Buchung ID: ${orderData.id}`, {
    //   x: 30,
    //   y: page.getHeight() - 380, // Позиция текста
    //   size: 8,
    //   font: font, // Встроенный шрифт
    //   color: rgb(0, 0, 0), // Цвет текста
    // });


    page.drawLine({
        start: { x: 40, y: page.getHeight() - 390 },
        end: { x: 555, y: page.getHeight() - 390 },
        thickness: 1.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });














    // page.drawRectangle({
    //   x: 400,
    //   y: page.getHeight() - 460,
    //   width: 110,
    //   height: 33,
    //   borderColor: rgb(0.7, 0.7, 0.7), // Use light gray color for the border
    //   borderWidth: 0.01, // Set border thickness
    // });

    // page.drawText('Kennzeichen', {
    //   x: 405,
    //   y: page.getHeight() - 457, // Adjust y position to be inside the border
    //   size: 8,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });


    // {
    //   orderData.statusMotorcycle1 && (
    //     page.drawText(orderData.motorcycle1.kennzeichen, {
    //       x: 405,
    //       y: page.getHeight() - 445, // Adjust y position to be inside the border
    //       size: 14,
    //       font: font, // Use the embedded font
    //       color: rgb(0, 0, 0), // Use the gray color for the text
    //     })
    //   )
    // }

    // {
    //   orderData.statusMotorcycle2 && (
    //     page.drawText(orderData.motorcycle2.kennzeichen, {
    //       x: 405,
    //       y: page.getHeight() - 445, // Adjust y position to be inside the border
    //       size: 14,
    //       font: font, // Use the embedded font
    //       color: rgb(0, 0, 0), // Use the gray color for the text
    //     })
    //   )
    // }


    page.drawText('Motorrad Rückgabe', {
        x: 40,
        y: page.getHeight() - 445, // Позиция текста
        size: 12,
        font: fontBold, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
    });

    page.drawText('Bezeichen Sie durch einen Pfeil den Punkt sichtbarer Mängel', {
        x: 40,
        y: page.getHeight() - 460, // Позиция текста
        size: 9,
        font: font, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
    });




    // Draw the image on the PDF page
    page.drawImage(image1, {
        x: 330,
        y: 160,
        width: 230,
        height: 200,
    });




    page.drawRectangle({
        x: 40,
        y: page.getHeight() - 710,
        width: 160,
        height: 33,
        borderColor: rgb(0.5, 0.5, 0.5), // Use light gray color for the border
        borderWidth: 0.01, // Set border thickness
    });

    page.drawText('Ort/Datum der Übernahme', {
        x: 45,
        y: page.getHeight() - 707, // Adjust y position to be inside the border
        size: 8,
        font: font, // Use the embedded font
        color: rgb(0.5, 0.5, 0.5), // Use the gray color for the text
    });



    page.drawRectangle({
        x: 210,
        y: page.getHeight() - 710,
        width: 110,
        height: 33,
        borderColor: rgb(0.5, 0.5, 0.5), // Use light gray color for the border
        borderWidth: 0.01, // Set border thickness
    });

    page.drawText('Uhrzeit', {
        x: 215,
        y: page.getHeight() - 707, // Adjust y position to be inside the border
        size: 8,
        font: font, // Use the embedded font
        color: rgb(0.5, 0.5, 0.5), // Use the gray color for the text
    });

    page.drawRectangle({
        x: 330,
        y: page.getHeight() - 710,
        width: 110,
        height: 33,
        borderColor: rgb(0.5, 0.5, 0.5), // Use light gray color for the border
        borderWidth: 0.01, // Set border thickness
    });

    page.drawText('KM-Stand', {
        x: 335,
        y: page.getHeight() - 707, // Adjust y position to be inside the border
        size: 8,
        font: font, // Use the embedded font
        color: rgb(0.5, 0.5, 0.5), // Use the gray color for the text
    });



    page.drawText('Tank Füllstand', {
        x: 445,
        y: page.getHeight() - 684, // Adjust y position to be inside the border
        size: 8,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });




    // Draw the image on the PDF page
    page.drawImage(image2, {
        x: 445,
        y: page.getHeight() - 710,
        width: 20,
        height: 20,
    });

    page.drawText('leer', {
        x: 449,
        y: page.getHeight() - 702, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawImage(image2, {
        x: 466,
        y: page.getHeight() - 710,
        width: 20,
        height: 20,
    });

    page.drawText('1/4', {
        x: 471,
        y: page.getHeight() - 702, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawImage(image2, {
        x: 487,
        y: page.getHeight() - 710,
        width: 20,
        height: 20,
    });

    page.drawText('1/2', {
        x: 492,
        y: page.getHeight() - 702, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawImage(image2, {
        x: 508,
        y: page.getHeight() - 710,
        width: 20,
        height: 20,
    });

    page.drawText('3/4', {
        x: 513,
        y: page.getHeight() - 702, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawImage(image2, {
        x: 530,
        y: page.getHeight() - 710,
        width: 20,
        height: 20,
    });

    page.drawText('voll', {
        x: 534,
        y: page.getHeight() - 702, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });



    page.drawText('Unterschrift Vertreter Händler', {
        x: 42,
        y: page.getHeight() - 768, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawLine({
        start: { x: 40, y: page.getHeight() - 760 },
        end: { x: 295, y: page.getHeight() - 760 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });

    page.drawText('Unterschrift Benutzer', {
        x: 307,
        y: page.getHeight() - 768, // Adjust y position to be inside the border
        size: 7,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawLine({
        start: { x: 305, y: page.getHeight() - 760 },
        end: { x: 555, y: page.getHeight() - 760 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });


    //   page.drawText(`Buchung ID: ${orderData.id}`, {
    //     x: 30,
    //     y: page.getHeight() - 810, // Позиция текста
    //     size: 8,
    //     font: font, // Встроенный шрифт
    //     color: rgb(0, 0, 0), // Цвет текста
    //   });

    page.drawText(`Buchungs-ID: ${orderData.id} | Seite 1/1`, {
        x: 453,
        y: page.getHeight() - 800, // Позиция текста
        size: 8,
        font: font, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
    });




}

// const PDFComponent3 = ({ orderData }) => {
//     const handleGeneratePDF = () => {
//         generateInteractivePDF(orderData);
//     };

const API_BASE_URL = 'https://admin.motorrad-mieten-kaernten.at';

const PDFComponent3 = ({ orderData, userFullName }) => {
  const handleGeneratePDF = async () => {
    try {
      const now = new Date();
      now.setHours(now.getHours() + 2);

      // Шаг 1: Создание новой записи
      const response = await fetch(`${API_BASE_URL}/items/records/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderId: orderData.id,
          name: userFullName,
          docType: 'Ubernahme',
          date: now.toISOString(),
        }),
      });

      if (!response.ok) {
        throw new Error('Ошибка при создании записи');
      }

      const newRecord = await response.json();
      console.log('Запись успешно создана:', newRecord);

      const newRecordId = newRecord.id || newRecord.data?.id;
      if (!newRecordId) {
        throw new Error('ID новой записи не найден');
      }

      // Шаг 2: Проверка на существование связи
      const exists = await checkRecordExists(orderData.id, newRecordId);
      if (exists) {
        console.log('Запись уже существует для данного заказа:', newRecordId);
        return; // Выходим, если связь уже существует
      }
      
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Шаг 3: Обновляем промежуточную таблицу Orders_records
      await updateOrdersRecords(orderData.id, newRecordId);
      await generateInteractivePDF(orderData);

      // Шаг 4: Добавляем ID в массив записей заказа
      // await updateOrderRecords(orderData.id, newRecordId);

    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  const checkRecordExists = async (orderId, newRecordId) => {
    try {
      const currentRecordsResponse = await fetch(`${API_BASE_URL}/items/Orders/${orderId}?fields=records`);
      const currentRecordsData = await currentRecordsResponse.json();

      const currentRecords = Array.isArray(currentRecordsData.data.records) ? currentRecordsData.data.records : [];
      return currentRecords.includes(newRecordId);
    } catch (error) {
      console.error('Ошибка при проверке существования записи:', error);
      return false;
    }
  };

  const updateOrdersRecords = async (orderId, newRecordId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/items/Orders_records_1`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Orders_id: orderId,
          records_id: newRecordId,
        }),
      });

      if (!response.ok) {
        throw new Error('Ошибка при обновлении промежуточной таблицы');
      }

      const result = await response.json();
      console.log('Промежуточная таблица успешно обновлена:', result);

    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

    return (
        <div>
            <button className='panelBtnFilterFix' onClick={handleGeneratePDF}>UBERNAHME/RÜCKGABE</button>
        </div>
    );
};

export default PDFComponent3;