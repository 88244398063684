import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';


function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">

                <div className="links-container">
                    <div className="social-links">
                        <a href="/datenschutz" rel="noopener noreferrer">Datenschutz</a> | <a href="/agb" rel="noopener noreferrer">AGBs</a> 
                    </div>
                    <div className="footer-links">
                        <p>
                            office@motorrad-mieten.at
                        </p>
                        <p>
                            © 2024 Z-Works
                        </p>
                        {/* <a href="" target="_blank">office@motorrad-mieten-kaernten.at</a>
                        <a href="" target="_blank">© 2024 Z-Works</a> */}
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
