import React from 'react';
import './Gallery.css';

const Modal = ({ imageUrl, closeModal }) => {
  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={imageUrl} alt="Original" />
        <span className="modal-close" onClick={closeModal}></span>
      </div>
    </div>
  );
};

export default Modal;
