import React from 'react';

const AGB = () => {
  return (
    <div className="static-content">
      <h1>Allgemeine Geschäftsbedingungen</h1>
      <p>1) Das Mietverhältnis ist für die vertraglich festgesetzte Zeit vereinbart. Die Rückführung des Fahrzeuges hat der Mieter an die Adresse des Vermieters bzw. an den Ort durchzuführen, der mit der Fuhrparkverwaltung des Vermieters vereinbart wurde. Bei nicht rechtzeitiger Rückgabe ist der Mieter für jeden Schaden haftbar, der dem Vermieter aus der Vorenthaltung des Besitzes entsteht. Der Mieter erkennt den mangelfreien und verkehrssicheren Zustand des Fahrzeuges, seiner Ausrüstung (z.B. Werkzeug) sowie den angeführten km-Stand ausdrücklich an. Der Mieter verzichtet auf jede Geltendmachung von durch Ausfallen des Mietfahrzeuges entstandenen Kosten oder Verdienstentgang. Der Mieter ist verpflichtet, den Wert von nicht zurückgegebenen Bestandteilen, Werkzeug und dergleichen bei der Rückgabe des Fahrzeuges zu bezahlen. Das Fahrzeug wird getankt und in sauberem Zustand übergeben und ist dementsprechend zurückzugeben.</p>
      <p>2) Die Überlassung des Fahrzeuges an einen unberechtigten (im Mietvertrag nicht angeführten) Dritten ist nicht gestattet. Ein Verstoß gegen diese Bestimmung hat zur Folge, dass der Mieter für die beider Fahrt durch unberechtigte Dritte entstan-denen Haftpflicht- und Fahrzeugschäden selbst aufkommen muss, bzw. sich gegenüber der Versicherungsgesellschaft des Vermieters regresspflichtig macht. Der Mieter verpflichtet sich zum jederzeitigen Nachweis darüber, wer das Fahrzeug jeweils gefahren hat, auch für den Fall, dass die Überlassung des Fahrzeuges an den Fahrer nicht erlaubt war. Bei Verstoß dagegen haftet der Mieter für den Fall, dass der Fahrer nicht ermittelt werden kann, für jeglichen Schaden, der dem Vermieter aus dieser Unterlassung entsteht. </p>
      <p>3) Das Mietfahrzeug ist auf den Vermieter zugelassen und von diesem gegen Haftpflicht bis zu einem Pauschal-Höchstbetrag von €10.000.000,00 versichert. Durch die Versicherung nicht abgedeckte Schäden gehen zu Lasten des Mieters. Sollte der Mieter mit dem Fahrzeug an einem Verkehrs- ereignis beteiligt sein, welches als Versicherungsfall anzusehen ist, hat er die Fuhrpark-Verwaltung des Vermieters längstens binnen 48 Stunden von dem Unfall zu verständigen. Der Bericht an den Vermieter soll in Form des Europäischen Unfallberichtes erfolgen und auf jeden Fall folgenden Angaben enthalten:-  Datum und Uhrzeit des Unfalles-  Ort des Unfalles-  Adresse des Fahrers des Fahrzeuges zum Zeitpunkt des Unfalles-  Angaben über Führerschein des Fahrers (Klasse, ausstellende Behörde, Ausstellungsdatum)-  Adresse der anderen am Unfall beteiligten Personen und Kennzeichen des/der am Unfall beteiligten Fahrzeugs/ Fahrzeuge-  Detaillierter Bericht über den Unfall (einschließlich Zeichnung) sowie Namen und Adressen möglicher Zeugen-  Schadensausmaß (Verletzung, Tod, Sachschaden)-  Angabe über den Ort, an dem sich das Fahrzeug befindet Schadenserhebungen dürfen erst nach Einholung der Zustimmung des Vermieters veranlasst werden.</p> 
      <p>4) Fahrten sind innerhalb Österreichs vollkaskoversichert, mit einem fix definierten Selbstbehalt. Für Fahrten außerhalb Österreichs besteht kein Versicherungsschutz. Das Fahrzeug darf nicht für Renn- und Zuverlässigkeitsfahrten oder sonstige motorsportliche Veranstaltungen oder Trainingsfahrten verwendet werden. Eine Benützung zur gewerbemäßigen Beförderung ist untersagt. Zudem ist der Mieter angehalten, sämtliche eventuell zur Ein- oder Ausfuhr gelangenden Waren im Zuge eines Grenz- übertrittes ordnungsgemäß zu deklarieren. </p>
      <p>5) Der Mieter hat das Fahrzeug sachgemäß und pfleglich zu behandeln und die laut Betriebsanleitung vorgeschriebenen Wartungsdienste beim Vermieter bzw. einer mit diesem ausdrücklich vereinbarten anderen offiziellen Vertragswerkstätte termingerecht vornehmen zu lassen. Die dafür anfallenden Kosten sowie Reparaturkosten am Mietfahrzeug trägt der Vermieter. Sollten am Fahrzeug irgendwelche größeren Mängel auftreten, ist die Fuhrparkverwaltung des Vermieters hiervon unverzüglich zu verständigen. Reparaturen darf der Mieter nur nach ausdrücklicher Zustimmung des Vermieters durchführen lassen. Die Wahl der Reparaturwerkstätte steht in jedem Fall dem Vermieter zu. </p>
      <p>6) Machen dritte Personen Ansprüche auf das Fahrzeug geltend, ist der Mieter verpflichtet, das Mietfahrzeug als Eigentum des Vermieters zu deklarieren und diesem als Wahrung der Eigentumsrechte sofort Nachricht zu geben. </p>
      <p>7) Der Mieter hat an dem im Eigentum des Vermieters stehenden Kraftfahrzeug kein Zurückbehaltungsrecht gegenüber dem Vermieter. </p>
      <p>8) Der Mieter haftet vom Zeitpunkt der Übernahme bis zur Rückgabe des Fahrzeuges dem Vermieter gegenüber für sämtliche Schäden infolge vorsätzlicher oder grob fahrlässiger Schadensverursachung, alkoholbedingter Fahruntüchtigkeit des Fahrzeuglenkers sowie alle durch das Ladegut entstandenen Schäden. </p>
      <p>9) Der Vermieter haftet für keinerlei Schäden, die dem Mieter oder Dritten im Zusammenhang mit der Überlassung des Fahrzeuges entstehen, es sei denn, der Vermieter handelt vorsätzlich oder grob fahrlässig. Der Mieter wird den Vermieter von Ansprüchen Dritter aufgrund von Unfällen freistellen, soweit und solange nicht die Haftpflichtversicherung des Vermieters für den Schaden eintritt. Fälle, in denen die Versicherung zwar einen Schaden regulieren muss, jedoch aufgrund gesetzlicher Bestimmungen gegen den Mieter oder seinen Fahrer Rückgriff nehmen kann, berühren den Vermieter nicht. Der Mieter stellt den Vermieter von sämtlichen Ansprüchen aus der Verletzung von Gesetzen, Verordnungen oder sonstigen Vorschriften im Zusammenhang mit dem Gebrauch des Fahrzeuges durch ihn frei. Der Vermieter ist berechtigt, bei Inanspruchnahme Zahlungen zu leisten und beim Mieter Rückgriff zu nehmen. </p>
      <p>10) Als Miete gelten die vertraglich festgelegten Beträge. Sämtliche Treibstoffkosten (inkl. MwSt.) gehen in jedem Fall zu Lasten des Mieters. Die Zahlung der vollen Miete hat auch dann zu erfolgen, wenn das Fahrzeug nicht den vollen Tag im Besitz des Mieters war. Die Zahlung für die gesamte Mietdauer hat anlässlich der Rückstellung des Fahrzeuges zu erfolgen. Der Auftraggeber erteilt ausdrücklich seine Zustimmung, dass im Falle exekutiver gerichtlicher Geltendmachung eines ausständigen Zahlungsbetrages, durch den Vermieter die Kundendaten an die Warenkreditevidenz des Kreditschutzverbandes 1870 übermittelt werden. </p>
      <p>11) Im Übrigen gelten die einschlägigen Vorschriften des Allgemeinen Bürgerlichen Gesetzbuches. Erfüllungsort und Gerichtsstand für sämtliche Ansprüche aus diesem Vertrag oder im Zusammenhang damit ist Klagenfurt.</p>
    </div>
  )
};

export default AGB;