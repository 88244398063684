import React, { useState, useEffect } from 'react';
import { getMotorcycles, updateMotorcycle } from '../../services/apiService';
import './Motorcycles.css';

function Motorcycles({ setSelectedMotorcycle }) {
    const [motorcycles, setMotorcycles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedMotorcycleId, setSelectedMotorcycleId] = useState(null);
    const [selectedMotorcycleStatus, setSelectedMotorcycleStatus] = useState(null);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [selectedColor, setSelectedColor] = useState('#FFFFFF');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getMotorcycles();
                setMotorcycles(data);
            } catch (error) {
                console.error('Error fetching motorcycles:', error);
            }
        };

        fetchData();
    }, []);

    const openModal = (id, currentStatus) => {
        setSelectedMotorcycleId(id);
        setSelectedMotorcycleStatus(currentStatus);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedMotorcycleId(null);
        setSelectedMotorcycleStatus(null);
    };

    const handleUpdateStatus = async () => {
        if (selectedMotorcycleId !== null) {
            try {
                await updateMotorcycle(selectedMotorcycleId, { published: !selectedMotorcycleStatus });
                setMotorcycles(prevMotorcycles =>
                    prevMotorcycles.map(motorcycle =>
                        motorcycle.id === selectedMotorcycleId ? { ...motorcycle, published: !selectedMotorcycleStatus } : motorcycle
                    )
                );
                closeModal();
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } catch (error) {
                console.error('Error updating motorcycle status:', error);
            }
        }
    };

    const openColorPicker = (id, currentColor) => {
        setSelectedMotorcycleId(id);
        setSelectedColor(currentColor);
        setShowColorPicker(true);
    };

    const closeColorPicker = () => {
        setShowColorPicker(false);
        setSelectedMotorcycleId(null);
        setSelectedColor('#FFFFFF');
    };

    const handleColorChange = (event) => {
        setSelectedColor(event.target.value);
    };

    const confirmColorChange = async () => {
        if (selectedMotorcycleId !== null) {
            try {
                // Обновляем цвет в базе данных
                await updateMotorcycle(selectedMotorcycleId, { Color: selectedColor });
                setMotorcycles(prevMotorcycles =>
                    prevMotorcycles.map(motorcycle =>
                        motorcycle.id === selectedMotorcycleId ? { ...motorcycle, Color: selectedColor } : motorcycle
                    )
                );
                closeColorPicker();
                // Перезагрузка страницы через 1 секунду
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } catch (error) {
                console.error('Error updating motorcycle color:', error);
            }
        }
    };

    return (
        <div>
            <div>
                {/* <h1>Available Motorcycles</h1> */}
            </div>
            <div className="motorcycles-list">
                <table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Name</th>
                            <th>Farbe</th> {/* Добавляем столбец для цвета */}
                            <th>Tagesmiete (€)</th>
                            <th>Wochenendmiete (€)</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {motorcycles.map((motorcycle, index) => (
                            <tr key={motorcycle.id}>
                                <td>{index + 1}</td>
                                <td>{motorcycle.Name}</td>
                                <td className='color'>
                                    <div
                                        className="color-circle"
                                        style={{ backgroundColor: motorcycle.Color }}
                                        onClick={() => openColorPicker(motorcycle.id, motorcycle.Color)}
                                    />
                                </td>
                                <td>{motorcycle.daily_rate}</td>
                                <td>{motorcycle.weekend}</td>
                                <td>
                                    <button onClick={() => openModal(motorcycle.id, motorcycle.published)}>
                                        {motorcycle.published ? 'Zurückziehen' : 'Veröffentlichen'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Модальное окно подтверждения статуса */}
            {showModal && (
                <div className="modalM">
                    <div className="modal-contentM">
                        <h3>Sind Sie sicher, dass Sie den Status dieses Motorrads aktualisieren möchten?</h3>
                        <div className="modal-actionsM">
                            <button onClick={handleUpdateStatus}>Ja</button>
                            <button onClick={closeModal}>Nein</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Модальное окно выбора цвета */}
            {showColorPicker && (
                <div className="modalM">
                    <div className="modal-contentM">
                        <h3>Wählen Sie eine neue Farbe:</h3>
                        <input type="color" value={selectedColor} onChange={handleColorChange} />
                        <div className="modal-actionsM">
                            <button onClick={confirmColorChange}>Bestätigen</button>
                            <button onClick={closeColorPicker}>Abbrechen</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Motorcycles;
