import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';


function Navbar({ username, onLogout, unconfirmedOrdersCount }) {

    const location = useLocation();




    const [isMenuOpen, setIsMenuOpen] = useState(false);

    function toggleMenu() {
        setIsMenuOpen(!isMenuOpen);
    }

    // function smoothScroll(target) {
    //     const element = document.getElementById(target);
    //     if (element) {
    //         window.scrollTo({
    //             top: element.offsetTop,
    //             behavior: "smooth"
    //         });
    //     }
    // }

    function smoothScroll(target, duration = 2000) {
        const element = document.getElementById(target);
        if (element) {
            const start = window.pageYOffset;
            const end = element.offsetTop;
            const distance = end - start;
            const startTime = performance.now();

            function scrollAnimation(currentTime) {
                const elapsed = currentTime - startTime;
                const progress = Math.min(elapsed / duration, 1);
                const ease = easeInOutQuad(progress);
                window.scrollTo(0, start + distance * ease);

                if (elapsed < duration) {
                    requestAnimationFrame(scrollAnimation);
                }
            }

            function easeInOutQuad(t) {
                return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
            }

            requestAnimationFrame(scrollAnimation);
        }
    }



    function getGreeting() {
        const currentHour = new Date().getHours();
        let adjective;
        if (currentHour >= 5 && currentHour < 12) {
            const morningAdjectives = ["schönen", "strahlenden", "fröhlichen", "sonnigen", "erfrischenden", "lebendigen", "friedlichen", "zauberhaften"];
            adjective = morningAdjectives[Math.floor(Math.random() * morningAdjectives.length)];
            return (
                <div>
                    <span>Ein {adjective} Morgen</span>
                    <span role="img" aria-label="Sunrise"> 🌄</span>
                </div>
            );
        } else if (currentHour >= 12 && currentHour < 18) {
            const dayAdjectives = ["wunderbaren", "sonnigen", "angenehmen", "strahlenden", "frischen", "lebendigen", "erfolgreichen", "zauberhaften"];
            adjective = dayAdjectives[Math.floor(Math.random() * dayAdjectives.length)];
            return (
                <div>
                    <span>Ein {adjective} Tag</span>
                    <span role="img" aria-label="Sun"> 🌅</span>
                </div>
            );
        } else if (currentHour >= 18 && currentHour < 24) {
            const eveningAdjectives = ["ruhigen", "gemütlichen", "entspannten", "stimmungsvollen", "magischen", "romantischen", "harmonischen", "zauberhaften"];
            adjective = eveningAdjectives[Math.floor(Math.random() * eveningAdjectives.length)];
            return (
                <div>
                    <span>Ein {adjective} Abend</span>
                    <span role="img" aria-label="Sunset"> 🌇</span>
                </div>
            );
        } else {
            const nightAdjectives = ["ruhigen", "romantischen", "sternenklaren", "erholsamen", "magischen", "friedlichen", "zauberhaften", "gemütlichen"];
            adjective = nightAdjectives[Math.floor(Math.random() * nightAdjectives.length)];
            return (
                <div>
                    <span>Eine {adjective} Nacht</span>
                    <span role="img" aria-label="Moon"> 🌃</span>
                </div>
            );
        }
    }

    return (
        <nav className="navbar container">
            {/* <div className="container"> */}
            <a href="/" className="navbar-logo">motorrad-mieten.at</a>

            {(location.pathname.startsWith("/orders") && !sessionStorage.getItem('userId')) ?

                ''

                : sessionStorage.getItem('userId') && location.pathname.includes("/orders/list") ?

                    <>


                        <button className="navbar-menu-icon" onClick={toggleMenu}>
                            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
                        </button>

                        <ul className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
                            {username && (
                                <li className="navbar-item">
                                    {/* <span className="navbar-username">{getGreeting()}</span> */}
                                    <p>Willkommen {username}!</p>
                                    {/* <span style={{ marginLeft: '5px' }}>{username}!</span> */}
                                </li>
                            )}
                            {username && (
                                <li className="navbar-item">
                                    <Link to="/intern" className="navbar-link-admin">
                                        <div className="navbar-buchungen">
                                            SOFORT BUCHUNG
                                        </div>
                                    </Link>
                                </li>
                            )}
                            {username && (
                                <li className="navbar-item">
                                    <Link to="/orders/list" className="navbar-link-admin">
                                        <div className="navbar-buchungen">
                                            BUCHUNGEN
                                            {unconfirmedOrdersCount > 0 && (
                                                <span className="navbar-unconfirmed-orders">{unconfirmedOrdersCount}</span>
                                            )}
                                        </div>
                                    </Link>
                                </li>
                            )}
                            {username && (
                                <li className="navbar-item">
                                    <Link to="/orders/list/motorcycles" className="navbar-link-admin">
                                        <div className="navbar-buchungen">
                                            MOTORRÄDER
                                        </div>
                                    </Link>
                                </li>
                            )}

                            {(!location.pathname.startsWith("/motorcycle")) && (!location.pathname.startsWith("/orders/list")) && (!location.pathname.startsWith("/intern")) ? (
                                <>
                                    <li className="navbar-item">
                                        <button className="navbar-link" onClick={() => smoothScroll('mietpakete')}>MIETPAKETE</button>
                                    </li>
                                    <li className="navbar-item">
                                        <button className="navbar-link" onClick={() => smoothScroll('motorcycles')}>MOTORRÄDER</button>
                                    </li>
                                    <li className="navbar-item">
                                        <button className="navbar-link" onClick={() => smoothScroll('unterkünfte')}>UNTERKÜNFTE</button>
                                    </li>
                                    <li className="navbar-item">
                                        <button className="navbar-link" onClick={() => smoothScroll('bikeziele')}>BIKEZIELE</button>
                                    </li>
                                    <li className="navbar-item">
                                        <button className="navbar-link" onClick={() => smoothScroll('touren')}>TOUREN</button>
                                    </li>
                                    <li className="navbar-item">
                                        <a href="/" className="navbar-link">KONTAKT</a>
                                    </li>
                                </>
                            ) : (
                                <p></p>
                            )}

                            {username && (
                                <li className="navbar-item">
                                    <button className="navbar-button" onClick={onLogout}>Logout</button>
                                </li>
                            )}
                        </ul>
                    </>

                    :

                    <>


                        <button className="navbar-menu-icon" onClick={toggleMenu}>
                            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
                        </button>

                        <ul className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
                            {username && (
                                <li className="navbar-item">
                                    {/* <span className="navbar-username">{getGreeting()}</span> */}
                                    {/* <span style={{ marginLeft: '5px' }}>{username}!</span> */}
                                    <p>Willkommen {username}!</p>
                                </li>
                            )}
                            {username && (
                                <li className="navbar-item">
                                    <Link to="/intern" className="navbar-link-admin">
                                        <div className="navbar-buchungen">
                                            SOFORT BUCHUNG
                                        </div>
                                    </Link>
                                </li>
                            )}

                            {username && (
                                <li className="navbar-item">
                                    <Link to="/orders/list" className="navbar-link-admin">
                                        <div className="navbar-buchungen">
                                            BUCHUNGEN
                                            {unconfirmedOrdersCount > 0 && (
                                                <span className="navbar-unconfirmed-orders">{unconfirmedOrdersCount}</span>
                                            )}
                                        </div>
                                    </Link>
                                </li>
                            )}

                            {username && (
                                <li className="navbar-item">
                                    <Link to="/orders/list/motorcycles" className="navbar-link-admin">
                                        <div className="navbar-buchungen">
                                            MOTORRÄDER
                                        </div>
                                    </Link>
                                </li>
                            )}

                            {(!location.pathname.startsWith("/motorcycle")) && (!location.pathname.startsWith("/orders/list")) && (!location.pathname.startsWith("/intern")) ? (
                                <>
                                    <li className="navbar-item">
                                        <button className="navbar-link" onClick={() => smoothScroll('mietpakete')}>MIETPAKETE</button>
                                    </li>
                                    <li className="navbar-item">
                                        <button className="navbar-link" onClick={() => smoothScroll('motorcycles')}>MOTORRÄDER</button>
                                    </li>
                                    <li className="navbar-item">
                                        <button className="navbar-link" onClick={() => smoothScroll('unterkünfte')}>UNTERKÜNFTE</button>
                                    </li>
                                    <li className="navbar-item">
                                        <button className="navbar-link" onClick={() => smoothScroll('bikeziele')}>BIKEZIELE</button>
                                    </li>
                                    <li className="navbar-item">
                                        <button className="navbar-link" onClick={() => smoothScroll('touren')}>TOUREN</button>
                                    </li>
                                    <li className="navbar-item">
                                        <a href="/" className="navbar-link">KONTAKT</a>
                                    </li>
                                </>
                            ) : (
                                <p></p>
                            )}

                            {username && (
                                <li className="navbar-item">
                                    <button className="navbar-button" onClick={onLogout}>Logout</button>
                                </li>
                            )}
                        </ul>
                    </>

            }







            {/* </div> */}
        </nav>
    );
}

export default Navbar;
