import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import Modal from './Modal'; // Подключите ваш компонент Modal
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getMedia } from '../../services/apiService';
import './Gallery.css'



const Gallery = () => {
  const [miniGalleryImages, setMiniGalleryImages] = useState([]);
  const [origGalleryImages, setOrigGalleryImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        // Ваша функция getMedia должна быть определена и возвращать данные о медиафайлах из Directus
        const mediaData = await getMedia();
        console.log('Media data:', mediaData);

        const homeImages = mediaData.find(item => item.name === 'gallery');
        console.log('Gallery images:', homeImages);

        if (homeImages) {
          const miniImages = homeImages.images.filter(image =>
            image.directus_files_id.tags && image.directus_files_id.tags.includes('mini')
          );
          console.log('Filtered Miniimages:', miniImages);

          const origImages = homeImages.images.filter(image =>
            image.directus_files_id.tags && image.directus_files_id.tags.includes('orig')
          );
          console.log('Filtered OrigImages:', origImages);

          const mappedMiniImages = miniImages.map(image => ({
            id: image.directus_files_id.id,
            url: `https://admin.motorrad-mieten-kaernten.at/assets/${image.directus_files_id.id}`
          }));
          console.log('Mapped mini gallery images:', mappedMiniImages);

          const mappedOrigImages = origImages.map(image => ({
            id: image.directus_files_id.id,
            url: `https://admin.motorrad-mieten-kaernten.at/assets/${image.directus_files_id.id}`
          }));
          console.log('Mapped orig gallery images:', mappedOrigImages);

          setMiniGalleryImages(mappedMiniImages);
          setOrigGalleryImages(mappedOrigImages);
        }
      } catch (error) {
        console.error('Error fetching media:', error);
      }
    };

    fetchMedia();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };

  const handleMiniatureClick = (origImage) => {
    setSelectedImage(origImage);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className='slider' id='bikeziele'>
      <div className='slider-title'>
        <h1>
          Einige der schönsten Ziele im 3-Länder Eck
        </h1>
        <h1>
          mit unseren Miet-Bikes erfahren!
        </h1>
      </div>

      <Slider {...settings}>
        {miniGalleryImages.map((image, index) => (
          <div key={index}>
            <img src={image.url} alt={`Thumbnail ${index}`} onClick={() => handleMiniatureClick(origGalleryImages[index])} />
          </div>
        ))}
      </Slider>
      {selectedImage && <Modal imageUrl={selectedImage.url} closeModal={closeModal} />} {/* Передача URL изображения и функции закрытия модального окна */}
    </div>
  );
};

export default Gallery;
