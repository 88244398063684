import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getMotorcycles } from '../../services/apiService';
import './Motorcycles.css'

function MotorcycleDetails({ selectedMotorcycle, setSelectedMotorcycle, toggleFormClass }) {
    const { name } = useParams();
    const [motorcycles, setMotorcycles] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);


    const smoothScroll = (targetId) => {
        const target = document.getElementById(targetId);
        if (target) {
            target.scrollIntoView({ behavior: 'smooth' });
        }
    };


    // useEffect(() => {
    //     const fetchMotorcycles = async () => {
    //         try {
    //             const motorcyclesData = await getMotorcycles();
    //             setMotorcycles(motorcyclesData);
    //             const foundIndex = motorcyclesData.findIndex(motorcycle => motorcycle.Name.replace(/\s+/g, '-').toLowerCase() === name);
    //             setCurrentIndex(foundIndex);
    //         } catch (error) {
    //             console.error('Error fetching motorcycle details:', error);
    //         }
    //     };

    //     fetchMotorcycles();
    // }, [name]);

    useEffect(() => {
        const fetchMotorcycles = async () => {
            try {
                const motorcyclesData = await getMotorcycles();
                // Фильтруем только те мотоциклы, у которых published = true
                const filteredMotorcycles = motorcyclesData.filter(motorcycle => motorcycle.published === true);
                setMotorcycles(filteredMotorcycles);
                
                // Ищем индекс мотоцикла по имени после фильтрации
                const foundIndex = filteredMotorcycles.findIndex(motorcycle => 
                    motorcycle.Name.replace(/\s+/g, '-').toLowerCase() === name
                );
                setCurrentIndex(foundIndex);
            } catch (error) {
                console.error('Error fetching motorcycle details:', error);
            }
        };
    
        fetchMotorcycles();
    }, [name]);
    

    useEffect(() => {
        // Найдем индекс выбранного мотоцикла в массиве
        const foundIndex = motorcycles.findIndex(motorcycle => motorcycle.Name === selectedMotorcycle);
        // Если мотоцикл найден, обновим currentIndex
        if (foundIndex !== -1) {
            setCurrentIndex(foundIndex);
        }
    }, [selectedMotorcycle, motorcycles]);

    const nextMotorcycle = () => {
        const newIndex = (currentIndex + 1) % motorcycles.length;
        setCurrentIndex(newIndex);
        setSelectedMotorcycle(motorcycles[newIndex].Name);
    };

    const prevMotorcycle = () => {
        const newIndex = (currentIndex - 1 + motorcycles.length) % motorcycles.length;
        setCurrentIndex(newIndex);
        setSelectedMotorcycle(motorcycles[newIndex].Name);
    };


    const motorcycle = motorcycles[currentIndex];

    if (!motorcycle) {
        return <div>Loading...</div>;
    }

    const handleButtonClick = () => {
        toggleFormClass();
        // Добавляем тайм-аут, чтобы дать DOM время отрисовать изменения
        setTimeout(() => smoothScroll('touren'), 300);
    };

    return (
        <div>




            <div className='motoPage'>

                <div className='motoPageLeft'>

                    <div className='titleArrows'>
                        <Link className='arrow left' to={`/motorcycle/${motorcycles[(currentIndex - 1 + motorcycles.length) % motorcycles.length].Name.replace(/\s+/g, '-').toLowerCase()}`} onClick={prevMotorcycle}>&#60;</Link>

                        <div className='title-head'>
                            <h1 className='title'>{motorcycle.Name}</h1>
                        </div>
                        <Link className='arrow right' to={`/motorcycle/${motorcycles[(currentIndex + 1) % motorcycles.length].Name.replace(/\s+/g, '-').toLowerCase()}`} onClick={nextMotorcycle}>&#62;</Link>

                    </div>


                    <img className='motoImage' src={`https://admin.motorrad-mieten-kaernten.at/assets/${motorcycle.image}`} alt="image" />

                </div>

                <div className='motoPageRight'>
                    <h1>Preise</h1>
                    <h2><b>Tagessatz: <span className='red'> € {motorcycle.daily_rate},-</span></b></h2>
                    <p>
                        Mo-Fr von 8:30 bis 17:30 Uhr</p>
                    <p>400 km frei</p>
                    <br />
                    <h2><b>Wochenende: <span className='red'> € {motorcycle.weekend},-</span></b></h2>
                    <p>
                        Fr ab 15:00 bis Mo 10:00 Uhr</p>
                    <p>800 km frei</p>

                    {/* <button className='btn2' onClick={toggleFormClass}>JETZT VERFÜGBARKEIT PRÜFEN</button> */}
                    <button className='btn2' onClick={handleButtonClick}>
                        JETZT VERFÜGBARKEIT PRÜFEN
                    </button>
                </div>


            </div>


        </div>
    );
}

export default MotorcycleDetails;
