import axios from 'axios';

const API_BASE_URL = 'https://admin.motorrad-mieten-kaernten.at';

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const getMotorcycles = async () => {
  try {
    const response = await apiService.get('/items/Motorcycles');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching motorcycles:', error);
    throw new Error('Error fetching motorcycles');
  }
};

export const updateMotorcycle = async (id, updatedData) => {
  try {
    const response = await apiService.patch(`/items/Motorcycles/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error(`Error updating motorcycle with id ${id}:`, error);
    throw new Error(`Error updating motorcycle with id ${id}`);
  }
};


export const getRestaurants = async () => {
  try {
    const response = await apiService.get('/items/Restaurants');
    return response.data;
  } catch (error) {
    console.error('Error fetching restaurants:', error);
    throw new Error('Error fetching restaurants');
  }
};

export const getOrders = async () => {
  try {
    const response = await apiService.get('/items/Orders?fields=*.*.*');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw new Error('Error fetching orders');
  }
};


// export const getOrders = async () => {
//   try {
//     const response = await apiService.get('/items/Orders', {
//       params: {
//         fields: [
//           '*.*', // Все поля из Orders
//           'orders_records.*', // Все поля из промежуточной таблицы orders_records
//           'orders_records.records_id.*', // Все поля из Records через промежуточную таблицу
//           'orders_records.records_id.name', // Поле name из таблицы Records
//           // Вы можете добавить другие поля, которые вам нужны
//         ].join(',')
//       }
//     });
//     return response.data.data;
//   } catch (error) {
//     console.error('Error fetching orders:', error);
//     throw new Error('Error fetching orders');
//   }
// };



export const getOrdersWithMotorcycleDetails = async () => {
  try {
    console.log('Fetching orders...');
    // Сделать запрос к /items/Orders для получения заказов
    const ordersResponse = await apiService.get('/items/Orders');
    const orders = ordersResponse.data.data;
    console.log('Orders fetched:', orders);

    // Обработать каждый заказ и получить данные о мотоцикле
    const ordersWithMotorcycleDetails = await Promise.all(orders.map(async order => {
      const motorcycleId = order.motorcycle1; // Получить идентификатор мотоцикла из заказа
      const motorcycleResponse = await apiService.get(`/items/Motorcycles/${motorcycleId}`); // Сделать запрос данных о мотоцикле по его идентификатору
      const motorcycleDetails = motorcycleResponse.data; // Получить данные о мотоцикле
      return { ...order, motorcycleDetails }; // Вернуть заказ с данными о мотоцикле
    }));

    console.log('Orders with motorcycle details:', ordersWithMotorcycleDetails);
    return ordersWithMotorcycleDetails;
  } catch (error) {
    console.error('Error fetching orders with motorcycle details:', error);
    throw new Error('Error fetching orders with motorcycle details');
  }
};


export const getMedia = async () => {
  try {
    const response = await apiService.get('/items/Media?fields=name,images.directus_files_id.tags,images.directus_files_id.id');
    const media = response.data.data;
    if (Array.isArray(media)) {
      console.log('Media:', media);
    } else {
      console.log('Media:', media);
    }
    return media;
  } catch (error) {
    console.error('Error fetching media:', error);
    throw new Error('Error fetching media');
  }
};

// export const updateOrderStatus = async (orderId, newStatuses) => {
//   try {
//       const response = await fetch(`${API_BASE_URL}/items/Orders/${orderId}`, {
//           method: 'PATCH', // Используем PATCH для обновления части данных
//           headers: {
//               'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(newStatuses), // Передаем все необходимые статусы для обновления
//       });

//       if (!response.ok) {
//           throw new Error('Failed to update order status');
//       }

//       // Возвращаем обновлённые данные заказа, если необходимо
//       const updatedOrder = await response.json();
//       return updatedOrder;
//   } catch (error) {
//       throw new Error('Failed to update order status: ' + error.message);
//   }
// };

export const updateOrderStatus = async (orderId, newStatuses) => {
  try {
      const response = await fetch(`${API_BASE_URL}/items/Orders/${orderId}`, {
          method: 'PATCH', // Используем PATCH для обновления части данных
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(newStatuses), // Передаем все необходимые статусы для обновления
      });

      // Логирование всего ответа для отладки
      const responseText = await response.text(); // Читаем текст ответа до преобразования в JSON
      console.log('Response status:', response.status);
      console.log('Response status text:', response.statusText);
      console.log('Response body:', responseText);

      if (!response.ok) {
          throw new Error('Failed to update order status');
      }

      // Возвращаем обновлённые данные заказа, если необходимо
      const updatedOrder = JSON.parse(responseText); // Преобразуем текст ответа в JSON
      return updatedOrder;
  } catch (error) {
      // Логирование ошибки для отладки
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
      throw new Error('Failed to update order status: ' + error.message);
  }
};


// export const updateRecord = async (orderId, userFullName) => {
//   try {
//     const response = await fetch(`${API_BASE_URL}/items/records/${orderId}`, {
//       method: 'PATCH', // Используем PATCH для обновления
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ userFullName }), // Передаем объект с userFullName
//     });

//     if (!response.ok) {
//       throw new Error('Failed to update order status');
//     }

//     const updatedOrder = await response.json(); // Возвращаем обновленные данные
//     return updatedOrder;
//   } catch (error) {
//     throw new Error('Failed to update order status: ' + error.message);
//   }
// };




export default apiService;
