import React from 'react';
import './Mietpakete.css';
import { useState } from 'react';

const Mietpakete = () => {
    const [accordionOpen, setAccordionOpen] = useState(null);

    const handleAccordionClick = (index) => {
        if (accordionOpen === index) {
            setAccordionOpen(null);
        } else {
            setAccordionOpen(index);
        }
    };
    return (
        <div className="mietpakete-container" id="mietpakete">
            <h2 className="mietpakete-title text">UNSERE MIETPAKETE</h2>
            <div className="mietpakete-grid">
                <div className="mietpaket_red">
                    <h1 className='mietpaket-h'>A</h1>
                    <h3 className="mietpaket-title">TAGESSATZ</h3>
                    <p className="mietpaket-description"><b>400 km frei</b></p>
                    <div className="mietpaket-features">
                        <p>Montag – Freitag</p>
                        <p>von 8:30 bis 17:30 Uhr</p>
                    </div>
                </div>
                <div className="mietpaket_red">
                    <h1 className='mietpaket-h'>B</h1>
                    <h3 className="mietpaket-title">WOCHENENDE</h3>
                    <p className="mietpaket-description"><b>800 km frei</b></p>
                    <div className="mietpaket-features">
                        <p>
                            Freitag ab 15:00 Uhr
                        </p>
                        <p>
                            bis Montag 10:00 Uhr
                        </p>
                    </div>
                </div>
                <div className="mietpaket">
                    <h3 className="mietpaket-title">Inklusive:</h3>
                    <div className="mietpaket-features">
                        <ul>
                            <li>Haftpflicht bis € 15.000.000,-</li>
                            <li>Kasko Selbstbehalt max. € 2.000,-</li>
                            <li>Nur in Österreich versichert!</li>
                        </ul>
                    </div>
                </div>
                <div className="mietpaket_blue">
                    <h3 className="mietpaket-title">Zusatzpaket um nur € 69,-</h3>
                    <div className="mietpaket-features">
                        <ul>
                            <li>Kasko Selbstbehalt MAX. € 1.000,-</li>
                            <li>Kasko gilt auch in Italien, Slowenien und Kroatien!</li>
                        </ul>
                    </div>
                </div>

            </div>

            <p className='mietpaketText'>Kaution: € 500,- (Bar oder Visa) | Mehrkilometer: € 0,50 | Vermietung vorbehaltlich Verfügbarkeit!</p>
            <div className="accordion-controls">
                <button className='mietpaket-btn' onClick={() => handleAccordionClick(2)}>Versicherung</button>
                <button className='mietpaket-btn' onClick={() => handleAccordionClick(1)}>Buchung und Reservierung</button>
                <button className='mietpaket-btn' onClick={() => handleAccordionClick(3)}>Inkludierte Freikilometer</button>
                <button className='mietpaket-btn' onClick={() => handleAccordionClick(4)}>Bezahlung</button>
                <button className='mietpaket-btn' onClick={() => handleAccordionClick(5)}>Kaution</button>
                <button className='mietpaket-btn' onClick={() => handleAccordionClick(6)}>Übernahme und Rückgabe</button>
                <button className='mietpaket-btn' onClick={() => handleAccordionClick(7)}>Sonstige Informationen</button>
                {/* Add more buttons for other accordion sections */}
            </div>
            {/* New accordion sections */}
            {accordionOpen === 1 && (
                <div className="mietpaket1">
                    <h3 className="mietpaket-title1">Buchung und Reservierung</h3>
                    <div className="mietpaket-features1">
                        <p>Bei Buchungen, die mehr als eine Woche im Voraus getätigt werden, ist eine Anzahlung von 25% erforderlich. Diese wird als Stornogebühr verrechnet, sollte die Reservierung nicht bis spätestens 72 Stunden vor Mietbeginn storniert werden. Gegen eine einmalige Gebühr von € 20,00 pro Buchung kann aufgrund der Wetterlage oder anderen persönlichen Gründen die Vermietung verschoben werden. Die Verschiebung ist bis 24 Stunden vor Mietbeginn möglich. In diesem Falle bleibt die Anzahlung erhalten.</p>
                    </div>
                </div>
            )}
            {accordionOpen === 2 && (
                <div className="mietpaket1">
                    <h3 className="mietpaket-title1">Versicherung</h3>
                    <div className="mietpaket-features1">
                        <h4>Für Fahrten in Österreich:</h4>
                        <p>Alle Motorräder sind mit je € 15 Millionen Haftpflicht versichert und mit einem fix definierten Selbstbehalt vollkaskoversichert (siehe Mietpakete).</p>
                        <h4>Für Fahrten nach Italien, Slowenien und Kroatien:</h4>
                        <p>Alle Motorräder sind mit je € 15 Millionen haftpflichtversichert. Als Zusatzpaket bieten wir auch einen Kasko Versicherungsschutz. Die Höhe des Selbstbehaltes entnehmen Sie bitte der Information auf dieser Website unter der Rubrik Mietpakete.</p>
                    </div>
                </div>
            )}
            {accordionOpen === 3 && (
                <div className="mietpaket1">
                    <h3 className="mietpaket-title1">Inkludierte Freikilometer</h3>
                    <div className="mietpaket-features1">
                        <p>Je nach gewähltem Tarif ist eine außergewöhnlich hohe Zahl an Freikilometern inkludiert (siehe Website Rubrik MIETPAKETE). Mehrkilometer werden mit € 0,50/km verrechnet. Gerne erstellen wir ein individuelles Angebot speziell nach Ihren Wünschen.</p>
                    </div>
                </div>
            )}
            {accordionOpen === 4 && (
                <div className="mietpaket1">
                    <h3 className="mietpaket-title1">Bezahlung</h3>
                    <div className="mietpaket-features1">
                        <p>Die Bezahlung des kompletten Mietpreises findet bei Abholung des Motorrades statt und kann mit Kreditkarte (VISA, Mastercard), EC-Karte oder in bar getätigt werden. Eventuell anfallende Kosten für Mehrkilometer, Benzin oder Reinigung sind bei der Fahrzeug-Rückgabe zu bezahlen.</p>
                    </div>
                </div>
            )}
            {accordionOpen === 5 && (
                <div className="mietpaket1">
                    <h3 className="mietpaket-title1">Kaution</h3>
                    <div className="mietpaket-features1">
                        <p>Die Höhe der Kaution beträgt 500 Euro und muss vor Fahrzeugübernahme in BAR bzw. Kreditkarte hinterlegt werden. Bei Rückgabe des Fahrzeuges und unter möglichen Abzügen für Schäden, Reinigung, Betankung, etc. erhalten Sie die Kaution wieder zurück.</p>
                    </div>
                </div>
            )}
            {accordionOpen === 6 && (
                <div className="mietpaket1">
                    <h3 className="mietpaket-title1">Übernahme und Rückgabe</h3>
                    <div className="mietpaket-features1">
                        <p>Die Fahrzeug-Ausgabe ist bei nicht österreichischen Staatsbürgern nur bei Vorlage eines gültigen amtlichen Führerscheins UND Reisepasses oder Personalausweises möglich.</p>
                        <p>Die Übernahme und Rückgabe des Motorrades ist grundsätzlich während unserer Geschäftszeiten möglich (siehe Kontakt). Außerhalb dieser Zeit bieten wir gegen eine Aufwandsentschädigung von 50 Euro die Möglichkeit der früheren bzw. späteren Übernahme/Rückgabe an.</p>
                        <p>Die Rückgabe des Motorrades hat mit dem gleichen Benzinstand zu erfolgen, der bei der Fahrzeug-Übergabe gegeben war. Bei stark verschmutzter Rückgabe des Motorrades wird eine Reinigungspauschale von mind. € 25,- (je nach Aufwand) verrechnet.</p>
                    </div>
                </div>
            )}
            {accordionOpen === 7 && (
                <div className="mietpaket1">
                    <h3 className="mietpaket-title1">Sonstige Informationen</h3>
                    <div className="mietpaket-features1">
                        <p>Alle angegebenen Preise verstehen sich inkl. 20% MwSt. Motorrad- und Zubehörvermietung vorbehaltlich Verfügbarkeit. Bei Kauf eines Motorrades innerhalb von 3 Monaten ab Vermietung werden 70% der Kosten einer Vermietung rückerstattet (gilt für Vermietungen bis maximal einem Wochenendtarif).</p>
                        <p>Sollte das reservierte Fahrzeug nicht zur vereinbarten Vermietung zur Verfügung stehen (Unfall, technischer Schaden, Diebstahl etc.), wird der Vermieter versuchen, ein gleichwertiges Modell bereitzustellen oder im Einvernehmen mit dem Mieter ein Motorrad der nächsthöheren Modellreihe anbieten.</p>
                        <p>Alle unsere Verleih-Motorräder sind mit einem GPS-System (Bike-Control24) ausgestattet. Die dabei aufgezeichneten Daten werden von uns streng vertraulich behandelt. Eine Weitergabe dieser Daten wird nur im Falle einer unberechtigten Entwendung bzw. im Zuge eines Verkehrsunfalles an die Exekutive erfolgen sowie zur Führung des elektronischen Fahrtenbuches für die Finanzbehörden dienen.</p>
                    </div>
                </div>
            )}


        </div>
    );
};

export default Mietpakete;
